import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TableDisplay } from "../TableDisplay/TableDisplay";
import axiosInstance from "../axiosApi";
import swal from "sweetalert";
import moment from "moment";

function DownloadHistory() {
	let { userId } = useParams();
	const [downloadData, setDownloadData] = useState([]);
	const [fullName, setFullName] = useState([]);

	useEffect(() => {
		async function downloadHistory() {
			try {
				const response = await axiosInstance.get(`/download_history/${userId ?? JSON.parse(localStorage.getItem("user")).id}`, { timeout: 0 });
				var tempdata = [];
				if (response.data.length > 0) {
					for (let i = 0; i < response.data.length; i++) {
						tempdata.push({
							download_date: moment(response.data[i].download_date).format('YYYY-MM-DD HH:mm:ss'),
							file_name: response.data[i].file_name,
							user: response.data[i].user,
						})
					}
					console.log("Set");
					setDownloadData(tempdata);
				} else {
					console.log("False");
					setDownloadData(false);
				}
				setFullName(response.data[0].user.first_name + " " + response.data[0].user.last_name);

			} catch (error) {
				if (error?.response?.status !== 404) {
					swal("Failed", "error", "error", {
						buttons: false,
						timer: 1000,
					});
				}
			}
		}

		downloadHistory();
	}, []);

	const columns = React.useMemo(() => [
		{
			Header: "Date",
			accessor: "download_date",
		},
		{
			Header: "File Name",
			accessor: "file_name",
		},
		// {
		// 	Header: "File Type",
		// 	accessor: "fileType",
		// },
		// {
		// 	Header: "Metadata",
		// 	accessor: "metadata",
		// },
	]);

	if (downloadData) {
		return (
			<div className='p-10'>
				<h2 className='title'>User: {fullName} {">"} Download History </h2>
				<TableDisplay columns={columns} data={downloadData} />
			</div>
		);
	} else {
		return (
			<div className='p-10'>
				<h2 className='title'>User {fullName} {">"} Download History </h2>
				<p>No data to display.</p>
			</div>
		);
	}
}
export default DownloadHistory;
