import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { cloneDeep } from "lodash";
// import hexData from "../../data/Metros_hex.geo.json";
import { useAtom } from "jotai";
import { Document, Page, View, PDFViewer, Text, Image, Link, Font } from "@react-pdf/renderer";
import { displayMunicNameAtom, growthBySectorIndustryFilterAtom, growthBySectorSic7Atom, industryPotentialFilterAtom, jobsGrowthDataAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialValueAtom, selectedSpatialLayerAtom, sic7StringAtom, spatialParameterAtom, wageDistributionFilterAtom, yearsRangeAtom, changeInMetroReportKeyAtom, municipalitiesAtom } from "../../shared/state/atoms";
import { Box, Typography } from "@mui/material";
import styles from "../styles/PdfStyles";
import axiosNoAuthInstance from "../../components/axiosApiNoAuth";
import { getLowestValue, numberFormatter } from "../../shared/utils";
import { Bar, BarChart, CartesianGrid, Cell, Line, LineChart, Pie, PieChart, ReferenceLine, Scatter, ResponsiveContainer, ScatterChart, XAxis, YAxis, ZAxis } from "recharts";
import { useWindowDimensions } from "../../shared/hooks";
import swal from "sweetalert";
import { DashboardMap } from "../../components/Dashboards/components";
import { useGeoJSON } from "../../shared/hooks";

// const hexData = lazy(() => import("../../data/Metros_hex.geo.json"));

function AllDashboardsPdf() {
  // Use the useGeoJSON hook to load the GeoJSON data, this can also return a loading state if needed
  const { geoData: hexData } = useGeoJSON("Metros_hex.geo.json");

  const [displayMunicname] = useAtom(displayMunicNameAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [selectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);
  const [municipalities] = useAtom(municipalitiesAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [sic7String] = useAtom(sic7StringAtom);
  const [jobsGrowthData] = useAtom(jobsGrowthDataAtom);
  const [reportKey, setReportKey] = useAtom(changeInMetroReportKeyAtom);
  const [industryStructureData, setIndustryStructureData] = useState([]);
  const [wageDistributionData, setWageDistributionData] = useState([]);
  const [wageDistributionOverviewData, setWageDistributionOverviewOverviewData] = useState([]);
  const [wageDistributionIndustryFilter] = useAtom(wageDistributionFilterAtom);
  const [wageDistributionUniqueLabels, setWageDistributionUniqueLabels] = useState([]);
  const [totalJobsGrowthData, setTotalJobsGrowthData] = useState([]);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [groupedMunicipalities, setGroupedMunicipalities] = useState([]);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [newJobsData, setNewJobsData] = useState([]);
  const [industryFilter] = useAtom(growthBySectorIndustryFilterAtom);
  const [growthBySectorMainData, setGrowthBySectorMainData] = useState([]);
  const [growthBySectorOtherData, setGrowthBySectorOtherData] = useState([]);
  const [growthBySectorData, setGrowthBySectorData] = useState([]);
  const [growthBySectorSic7] = useAtom(growthBySectorSic7Atom);
  const [industryProfileData, setIndustryProfileData] = useState([]);
  const [industryProfileIndustryTotal, setIndustryProfileTotal] = useState([]);
  const [industryPotentialData, setIndustryPotentialData] = useState([]);
  const [industryPotentialIndustryFilter] = useAtom(industryPotentialFilterAtom);
  const [exportersData, setExportersData] = useState([]);
  const [multiEstablishmentFirmsData, setMultiEstablishmentData] = useState([]);
  const [wageInequalityData, setWageInequalityData] = useState([]);
  const [jobsForYouthData, setJobsForYouthData] = useState([]);
  const [wageLevelsResponseData, setWageLevelsResponseData] = useState([]);
  const [maxJobsValue, setMaxJobsValue] = useState(0);
  const [minJobsValue, setMinJobsValue] = useState(0);
  const [maxFirmsValue, setMaxFirmsValue] = useState(0);
  const [minFirmsValue, setMinFirmsValue] = useState(0);
  const [mapJobsData, setMapJobsData] = useState([]);
  const [mapJobsDataGrey, setMapJobsDataGrey] = useState([]);
  const [mapFirmsData, setMapFirmsData] = useState([]);
  const [mapFirmsDataGrey, setMapFirmsDataGrey] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [mapData, setMapData] = useState([]);
  const [mapDataGrey, setMapDataGrey] = useState([]);
  const [jobsGrowthImageUrl, setJobsGrowthImageUrl] = useState(null);
  const [industryStructureImageUrl, setIndustryStructureImageUrl] = useState(null);
  const [wageDistributionImageUrl, setWageDistributionImageUrl] = useState(null);
  const [wageDistributionOverviewImageUrl, setWageDistributionOverviewImageUrl] = useState(null);
  const [totalJobsGrowthImageUrl, setTotalJobsGrowthImageUrl] = useState(null);
  const [newJobsImageUrl, setNewJobsImageUrl] = useState(null);
  const [growthBySectorPageOneImageUrl, setGrowthBySectorPageOneImageUrl] = useState(null);
  const [growthBySectorPageTwoImageUrl, setGrowthBySectorPageTwoImageUrl] = useState(null);
  const [growthBySectorPageThreeImageUrl, setGrowthBySectorPageThreeImageUrl] = useState(null);
  const [industryProfileImageUrl, setIndustryProfileImageUrl] = useState(null);
  const [industryPotentialImageUrl, setIndustryPotentialImageUrl] = useState(null);
  const [exportersPageOneImageUrl, setExportersPageOneImageUrl] = useState(null);
  const [exportersPageTwoImageUrl, setExportersPageTwoImageUrl] = useState(null);
  const [exportersPageThreeImageUrl, setExportersPageThreeImageUrl] = useState(null);
  const [multiEstablishmentFirmsPageOneImageUrl, setMultiEstablishmentFirmsPageOneImageUrl] = useState(null);
  const [multiEstablishmentFirmsPageTwoImageUrl, setMultiEstablishmentFirmsPageTwoImageUrl] = useState(null);
  const [multiEstablishmentFirmsPageThreeImageUrl, setMultiEstablishmentFirmsPageThreeImageUrl] = useState(null);
  const [wageInequalityImageUrl, setWageInequalityImageUrl] = useState(null);
  const [jobsForYouthImageUrl, setJobsForYouthImageUrl] = useState(null);
  const [wageLevelsImageUrl, setWageLevelsImageUrl] = useState(null);
  const [changeInMetroImageUrl, setChangeInMetroImageUrl] = useState(null);
  const [jobDensityImageUrl, setJobDensityImageUrl] = useState(null);
  const [imageResponse, setImageResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const jobsGrowthRef = useRef();
  const industryStructureRef = useRef();
  const wageDistributionRef = useRef();
  const wageDistributionOverviewRef = useRef();
  const totalJobsGrowthRef = useRef();
  const newJobsRef = useRef();
  const growthBySectorPageOneRef = useRef();
  const growthBySectorPageTwoRef = useRef();
  const growthBySectorPageThreeRef = useRef();
  const industryProfileRef = useRef();
  const industryPotentialRef = useRef();
  const exportersPageOneRef = useRef();
  const exportersPageTwoRef = useRef();
  const exportersPageThreeRef = useRef();
  const multiEstablishmentFirmsPageOneRef = useRef();
  const multiEstablishmentFirmsPageTwoRef = useRef();
  const multiEstablishmentFirmsPageThreeRef = useRef();
  const wageInequalityRef = useRef();
  const jobsForYouthRef = useRef();
  const wageLevelsRef = useRef();
  const changeInMetroRef = useRef();
  const jobDensityRef = useRef();
  const wageDistributionColors = ["#e69d00", "#84746c"];
  const exportersColors = ["#f0a400", "#84746c", "#000", "#009640"];
  const multiEstablishmentFirmsColors = ["#f0a400", "#84746c", "#000", "#009640"];
  const wageInequalityDataColors = ["#f0a400", "#84746c", "black"];
  const jobsForYouthColors = ["#f0a400", "#009640"];
  const wageLevelsColors = ["#f0a400", " #84746c", "black"];
  const growthBySectorColors = ["#f0a400", "#009640"];
  const { height, width } = useWindowDimensions();

  const isMetroMunicipality = municipalities?.find((municipality) => municipality?.cat_b === selectedSpatialValue)?.category === "A";

  Font.register({ family: "Helvetica-Bold", fontStyle: "normal", fontWeight: "bold" });

  let pageNumber = 1;

  const PageNumber = ({ pageName }) => {
    const newPageNumber = pageNumber++;

    return newPageNumber;
  };

  const jobsOutput = "fte";
  const firmsOutput = "firms";

  function getJobDensityMapData() {
    setIsLoading(true);
    var vfield, vget_field, vpoly_field, poly_data;
    poly_data = hexData;

    var vpoly = cloneDeep(poly_data);
    var vpoly_grey = cloneDeep(poly_data);
    vfield = "display";
    vget_field = "hex7";
    vpoly_field = "hex7";
    var vmax = 0;
    var vmin = Number.MAX_SAFE_INTEGER;

    vpoly.features = [];
    vpoly_grey.features = [];

    axiosNoAuthInstance
      .get(`/api/map-explorer/?taxdata_modelname=hex7_fte&metro=${selectedPlace?.name}&year=${selectedFilters?.singleYear}&viewas=Absolute&secondyear=${yearsRange[yearsRange?.length - 1]}&spatial=hex7&output=fte&${spatialParameter}=${selectedSpatialValue}`)
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          for (let j = 0; j < poly_data.features.length; j++) {
            if (poly_data.features[j].properties[vpoly_field] === response.data[i][vget_field]) {
              if (typeof response.data[i][vfield] != "number") {
                let vfeature_grey = cloneDeep(poly_data.features[j]);
                vfeature_grey.properties.value = "0";
                vfeature_grey.properties.label = ["fte"];
                vfeature_grey.properties.label.push(response.data[i][vfield]);
                vpoly_grey.features.push(vfeature_grey);
              } else {
                let vfeature = cloneDeep(poly_data.features[j]);
                vfeature.properties.value = response.data[i][vfield];
                if (parseFloat(vfeature.properties.value) > parseFloat(vmax)) {
                  vmax = parseFloat(vfeature.properties.value);
                }
                if (parseFloat(vfeature.properties.value) < parseFloat(vmin)) {
                  vmin = parseFloat(vfeature.properties.value);
                }
                vfeature.properties.label = ["fte"];
                vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));

                vpoly.features.push(vfeature);
              }
              continue;
            }
          }
        }
        setMaxValue(vmax);
        setMinValue(vmin === Number.MAX_SAFE_INTEGER ? 0 : vmin);
        setMapData(vpoly);
        setMapDataGrey(vpoly_grey);
        setReportKey(reportKey + 1);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          swal("Failed", `${error?.response?.data?.detail}`, "warning", {
            timer: 10000,
            buttons: false,
          });
        } else if (error?.response == null) {
          setIsLoading(false);
          swal("No Data available!", "Please select other filters", "error", {
            timer: 10000,
            buttons: false,
          });
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      });
  }

  function getMetroMapData(output) {
    setIsLoading(true);
    var vfield, vget_field, vpoly_field, poly_data;
    poly_data = hexData;

    var vpoly = cloneDeep(poly_data);
    var vpoly_grey = cloneDeep(poly_data);
    vfield = "display";
    vget_field = "hex7";
    vpoly_field = "hex7";
    var vmax = 0;
    var vmin = Number.MAX_SAFE_INTEGER;

    vpoly.features = [];
    vpoly_grey.features = [];

    axiosNoAuthInstance
      .get(`/api/map-explorer/?taxdata_modelname=hex7_${output}&metro=${selectedPlace?.name}&year=${selectedFilters.startYear}&viewas=Absolute+Change&secondyear=${selectedFilters.endYear}&spatial=hex7&output=${output}&${spatialParameter}=${selectedSpatialValue}`)
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          for (let j = 0; j < poly_data.features.length; j++) {
            if (poly_data.features[j].properties[vpoly_field] === response.data[i][vget_field]) {
              if (typeof response.data[i][vfield] != "number") {
                let vfeature_grey = cloneDeep(poly_data.features[j]);
                vfeature_grey.properties.value = "0";
                vfeature_grey.properties.label = [output];
                vfeature_grey.properties.label.push(response.data[i][vfield]);
                vpoly_grey.features.push(vfeature_grey);
              } else {
                let vfeature = cloneDeep(poly_data.features[j]);
                vfeature.properties.value = response.data[i][vfield];
                if (parseFloat(vfeature.properties.value) > parseFloat(vmax)) {
                  vmax = parseFloat(vfeature.properties.value);
                }
                if (parseFloat(vfeature.properties.value) < parseFloat(vmin)) {
                  vmin = parseFloat(vfeature.properties.value);
                }
                vfeature.properties.label = [output];
                vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));

                vpoly.features.push(vfeature);
              }
              continue;
            }
          }
        }

        if (output === firmsOutput) {
          setMaxFirmsValue(vmax);
          setMinFirmsValue(vmin === Number.MAX_SAFE_INTEGER ? 0 : vmin);
          setMapFirmsData(vpoly);
          setMapFirmsDataGrey(vpoly_grey);
        }

        if (output === jobsOutput) {
          setMaxJobsValue(vmax);
          setMinJobsValue(vmin === Number.MAX_SAFE_INTEGER ? 0 : vmin);
          setMapJobsData(vpoly);
          setMapJobsDataGrey(vpoly_grey);
        }

        setIsLoading(false);
        setReportKey(reportKey + 1);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          swal("Failed", `${error?.response?.data?.detail}`, "warning", {
            timer: 10000,
            buttons: false,
          });
        } else if (error?.response == null) {
          setIsLoading(false);
          swal("No Data available!", "Please select other filters", "error", {
            timer: 10000,
            buttons: false,
          });
        } else {
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      });
  }

  useEffect(() => {

    if (spatialParameter === "cat_b" && isMetroMunicipality && selectedSpatialValue && yearsRange.length > 0 && selectedFilters.startYear && selectedFilters.endYear && !hexData){
      return; // hexData not ready for 3 metro maps
    }

    async function getData() {
      setIsLoading(true);
      try {
        const response = await Promise.all([
          axiosNoAuthInstance.get(`/api/dashboard-jobs-growth/?${spatialParameter}=${selectedSpatialValue}`),
          axiosNoAuthInstance.get(`/api/dashboard-industry-structure/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-wage-distribution/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-total-growth-comparison/?${spatialParameter}=${selectedSpatialValue}&metro=${selectedFilters?.isMetroDisplayed ? "1" : "0"}&national=${selectedFilters?.isNationalDisplayed ? "1" : "0"}&same_province=${selectedFilters?.isProvinceDisplayed ? "1" : "0"}&similar_size=${selectedFilters?.isSimilarSizeDisplayed ? "1" : "0"}&base_year=${selectedFilters?.baseYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-new-jobs-change-industry/?${spatialParameter}=${selectedSpatialValue}&startyear=${selectedFilters?.startYear}&endyear=${selectedFilters?.endYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-growth-by-sector/?${spatialParameter}=${selectedSpatialValue}&sic7_1d=${growthBySectorSic7}`),
          axiosNoAuthInstance.get(`/api/dashboard-industry-hierarchy/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-establishment-export-industry/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-establishment-multiest-industry/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-industry-profile-quadrants/?${spatialParameter}=${selectedSpatialValue}${sic7String}&startyear=${selectedFilters?.startYear}&endyear=${selectedFilters?.endYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-wage-distribution-industry/?${spatialParameter}=${selectedSpatialValue}${sic7String}&year=${selectedFilters?.singleYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-wage-inequality/?${spatialParameter}=${selectedSpatialValue}`),
          axiosNoAuthInstance.get(`/api/dashboard-jobs-for-youth-barchart/?${spatialParameter}=${selectedSpatialValue}&year=${selectedFilters?.singleYear}`),
          axiosNoAuthInstance.get(`/api/dashboard-wage-levels/?${spatialParameter}=${selectedSpatialValue}`),
        ]);

        let industryStructureData = response[1]?.data?.map((jobs) => ({
          ...jobs,
          value: jobs.value,
        }));

        industryStructureData.sort(function (a, b) {
          return b.value - a.value;
        });

        let industryStructureSum = 0;
        for (let i = 10; i < industryStructureData.length; i++) {
          industryStructureSum += industryStructureData[i].value;
        }
        industryStructureData = industryStructureData.slice(0, 10);
        industryStructureData.push({
          cat_b: "CPT",
          year: yearsRange[yearsRange.length - 1],
          code: -1,
          activity: "Other",
          activity_shortname: "Other",
          value: industryStructureSum,
          count: 0,
        });

        const parsedData = response[3]?.data.map((totalGrowth) => ({
          ...totalGrowth,
          value: parseFloat(totalGrowth.value.toFixed(2)),
        }));

        const groupedData = parsedData.reduce((group, product) => {
          const { year } = product;
          group[year] = group[year] ?? [];
          group[year].push(product);
          return group;
        }, {});

        const mappedData = Object.keys(groupedData).map((year) => {
          let yearObject = groupedData[year].reduce((acc, cur) => {
            acc[cur.place_name] = cur.value;
            return acc;
          }, {});
          return { year, ...yearObject };
        });

        const groupedMunics = parsedData.reduce((acc, cur) => {
          const { place_name, hex_colour } = cur;
          const existingObj = acc.find((obj) => obj.place_name === place_name);

          if (existingObj) {
            existingObj.data.push({ year: cur.year, value: cur.value });
          } else {
            acc.push({
              place_name,
              hex_colour,
              data: [{ year: cur.year, value: cur.value }],
            });
          }

          return acc;
        }, []);

        let jobsData = response[4]?.data?.map((jobs) => ({
          ...jobs,
          value: jobs.value,
        }));

        jobsData?.sort(function (a, b) {
          return b.value - a.value;
        });

        let newJobsSum = 0;
        for (let i = 10; i < jobsData?.length; i++) {
          newJobsSum += jobsData[i].value;
        }
        jobsData = jobsData?.slice(0, 10);
        jobsData?.push({
          cat_b: "CPT",
          year: yearsRange[yearsRange.length - 1],
          code: -1,
          activity: "Other",
          activity_shortname: "Other",
          value: newJobsSum,
          count: 0,
        });

        const totalSize = response[6]?.data[0].plot_details[0][1][0]?.data?.reduce((acc, category) => {
          return acc + category.size;
        }, 0);

        const responseData = response[10]?.data.map((distribution) => ({
          ...distribution,
        }));

        setWageDistributionUniqueLabels(
          responseData?.reduce((acc, curr) => {
            curr.line_details?.forEach((item) => {
              Object.values(item)?.forEach((value) => {
                if (!acc.some((x) => x.label === value[0].label)) {
                  acc.push({ label: value[0].label, color: value[0].color, legend_label: value[0].legend_label });
                }
              });
            });
            return acc;
          }, [])
        );

        const processedData = responseData.map((item) => {
          const graphLabel = item.graph_label;
          const lines = item.line_details.reduce((acc, lines) => [...acc, ...Object.values(lines)], []);
          const yearValues = {};

          // eslint-disable-next-line array-callback-return
          lines.map((line) => {
            line.forEach((lineItem) => {
              lineItem.data.forEach((date) => {
                if (!yearValues[date.wage_band]) {
                  yearValues[date.wage_band] = {
                    wage_band: date.wage_band,
                    x_axis_label: item.x_axis_label,
                    y_axis_label: item.y_axis_label,
                  };
                }

                yearValues[date.wage_band][lineItem.label] = date.value;
              });
            });
          });

          return {
            graph_label: graphLabel,
            data: Object.values(yearValues),
          };
        });

        const mappedResponse = response[5]?.data.map((growth) => ({ ...growth }));

        const reducedData = mappedResponse.reduce((acc, curr) => {
          const graph = curr.graph;
          const graph_label = curr.graph_label;
          const y_axis_label = curr.y_axis_label;
          const lineDetails = curr.line_details[0];

          Object.values(lineDetails).forEach((lines) => {
            lines.forEach((line) => {
              acc.push({
                graph,
                graph_label,
                y_axis_label,
                line: {
                  line_type: line.line_type,
                  place_id: line.place_id,
                  industry_level: line.industry_level,
                  label: line.label,
                  data: line.data.map((d) => ({
                    year: d.year,
                    value: d.value,
                  })),
                },
              });
            });
          });

          return acc;
        }, []);

        const growthBySectorGroupedData = Object.values(
          reducedData.reduce((acc, item) => {
            acc[item.graph] = acc[item.graph] || [];
            acc[item.graph].push(item);
            return acc;
          }, {})
        );

        let finalData = [];

        // eslint-disable-next-line array-callback-return
        growthBySectorGroupedData.map((item) => {
          let result = {};

          item.forEach(({ graph_label, y_axis_label, line: { label, data } }) => {
            result.graph_label = graph_label;
            result.data = result.data || [];
            result.y_axis_label = y_axis_label;

            data.forEach(({ year, value }) => {
              let yearData = result.data.find((d) => d.year === year);
              if (!yearData) {
                yearData = { year };
                result.data.push(yearData);
              }

              yearData[label] = value;
            });
          });

          finalData = [...finalData, result];
        });

        if (response[1]) setIndustryStructureData(industryStructureData);
        if (response[2]) setWageDistributionOverviewOverviewData(response[2].data);
        if (response[3]) setTotalJobsGrowthData(mappedData);
        if (response[3]) setGroupedMunicipalities(groupedMunics);
        if (response[4]) setNewJobsData(jobsData);
        if (response[5]) setGrowthBySectorData(mappedResponse);
        if (response[5]) setGrowthBySectorMainData(finalData[0]);
        if (response[5]) setGrowthBySectorOtherData(finalData.slice(1));
        if (response[6])
          setIndustryProfileData(
            response[6]?.data.map((industry) => ({
              ...industry,
            }))
          );
        if (response[6]) setIndustryProfileTotal(totalSize);
        if (response[7])
          setExportersData(
            response[7]?.data.map((exporter) => ({
              ...exporter,
            }))
          );
        if (response[8])
          setMultiEstablishmentData(
            response[8]?.data.map((firms) => ({
              ...firms,
            }))
          );
        if (response[9])
          setIndustryPotentialData(
            response[9]?.data.map((distribution) => ({
              ...distribution,
            }))
          );
        if (response[10]) setWageDistributionData(processedData);
        if (response[11])
          setWageInequalityData(
            response[11]?.data.map((wage) => ({
              ...wage,
            }))
          );
        if (response[12])
          setJobsForYouthData(
            response[12]?.data.map((item) => {
              const { year, graph_label, plot_details } = item;
              const [
                {
                  1: [{ data, y_label, z_label }],
                },
              ] = plot_details;

              return {
                year,
                graph_label,
                y_label,
                z_label,
                data: data.map((point) => ({
                  x: point.x,
                  y: point.y,
                  z: point.z,
                  point_label: point.point_label,
                  point_short_label: point.point_short_label,
                })),
              };
            })
          );
        if (response[13]) setWageLevelsResponseData(response[13]?.data.map((wage) => ({ ...wage })));

        if (spatialParameter === "cat_b" && isMetroMunicipality && selectedSpatialValue && yearsRange.length > 0 && selectedFilters.startYear && selectedFilters.endYear) {
          getMetroMapData(firmsOutput);
          getMetroMapData(jobsOutput);
          getJobDensityMapData();
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (spatialParameter && selectedSpatialValue && yearsRange?.length > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spatialParameter,isMetroMunicipality, selectedSpatialValue, yearsRange, selectedFilters, selectedFilters, hexData, spatialParameter, selectedSpatialValue]);

  useEffect(() => {
    async function getData() {
      try {
        let response;

        if (spatialParameter !== "error") {
          response = await axiosNoAuthInstance.get(`/api/pdfprint-images/?${spatialParameter}=${selectedSpatialValue}`);
        }

        if (response) {
          setImageResponse(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    const convertNodeToImage = async (nodeRef, setImageUrl) => {
      setTimeout(async () => {
        toPng(nodeRef.current)
          .then((dataUrl) => {
            setImageUrl(dataUrl);
          })
          .catch((err) => {
            console.error("Failed to convert node to image:", err);
          });
      }, 10000);
    };

    const loadImages = async () => {
      await Promise.all([
        convertNodeToImage(jobsGrowthRef, setJobsGrowthImageUrl),
        industryStructureData.length > 0 && convertNodeToImage(industryStructureRef, setIndustryStructureImageUrl),
        convertNodeToImage(wageDistributionRef, setWageDistributionImageUrl),
        convertNodeToImage(wageDistributionOverviewRef, setWageDistributionOverviewImageUrl),
        convertNodeToImage(totalJobsGrowthRef, setTotalJobsGrowthImageUrl),
        convertNodeToImage(newJobsRef, setNewJobsImageUrl),
        convertNodeToImage(growthBySectorPageOneRef, setGrowthBySectorPageOneImageUrl),
        convertNodeToImage(growthBySectorPageTwoRef, setGrowthBySectorPageTwoImageUrl),
        convertNodeToImage(growthBySectorPageThreeRef, setGrowthBySectorPageThreeImageUrl),
        convertNodeToImage(industryProfileRef, setIndustryProfileImageUrl),
        convertNodeToImage(industryPotentialRef, setIndustryPotentialImageUrl),
        convertNodeToImage(exportersPageOneRef, setExportersPageOneImageUrl),
        convertNodeToImage(exportersPageTwoRef, setExportersPageTwoImageUrl),
        convertNodeToImage(exportersPageThreeRef, setExportersPageThreeImageUrl),
        convertNodeToImage(multiEstablishmentFirmsPageOneRef, setMultiEstablishmentFirmsPageOneImageUrl),
        convertNodeToImage(multiEstablishmentFirmsPageTwoRef, setMultiEstablishmentFirmsPageTwoImageUrl),
        convertNodeToImage(multiEstablishmentFirmsPageThreeRef, setMultiEstablishmentFirmsPageThreeImageUrl),
        convertNodeToImage(wageInequalityRef, setWageInequalityImageUrl),
        convertNodeToImage(jobsForYouthRef, setJobsForYouthImageUrl),
        convertNodeToImage(wageLevelsRef, setWageLevelsImageUrl),
        spatialParameter === "cat_b" && isMetroMunicipality && convertNodeToImage(changeInMetroRef, setChangeInMetroImageUrl),
        spatialParameter === "cat_b" && isMetroMunicipality && convertNodeToImage(jobDensityRef, setJobDensityImageUrl),
      ]);
    };

    loadImages();
    getData();
  }, [selectedSpatialValue, spatialParameter, industryStructureData, isMetroMunicipality]);

  const lowestValue = getLowestValue(jobsGrowthData);

  const industryProfileDataOuter = industryProfileData[0]?.plot_details[0][1][0].data.flatMap(({ children }) => children);

  if (exportersData[0]?.bar_chart[0].data.length === 3) {
    exportersColors.splice(2, 1);
  }

  if (multiEstablishmentFirmsData[0]?.bar_chart[0].data.length === 3) {
    multiEstablishmentFirmsColors.splice(2, 1);
  }

  const TotalJobsGrowthLegendSection = () => (
    <View style={{ ...styles.legendSection, marginBottom: 10, marginTop: 10 }}>
      <Text style={{ ...styles.legendText, position: "absolute", top: -20, left: 220, fontSize: 14, textDecoration: "underline", marginBottom: 2 }}>{selectedFilters?.endYear}</Text>
      {groupedMunicipalities.map((munic) => (
        <View style={{ ...styles.legendLine, width: 300, justifyContent: "space-between" }}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ ...styles.legend, backgroundColor: munic.hex_colour }} />
            <Text style={styles.legendText}>{munic.place_name}</Text>
          </View>
          <Text style={{ ...styles.legendText, width: 80 }}>{munic.data[munic.data.length - 1].value}</Text>
        </View>
      ))}
    </View>
  );

  const GrowthBySectorSmallChartComponent = ({ dataSlice }) => (
    <div style={{ display: "flex", flexWrap: "wrap", marginLeft: 105 }}>
      {dataSlice.map((chart) => (
        <div style={{ marginRight: 20 }}>
          <div style={{ height: 90, width: 400 }}>{chart?.graph_label}</div>
          <ResponsiveContainer width={300} height={300}>
            <LineChart
              data={chart?.data}
              height={height * 0.4}
              width={(width - 400) * 0.3}
              margin={{
                top: 10,
                bottom: 10,
              }}
            >
              <XAxis dataKey="year" />
              <YAxis
                domain={["auto", "auto"]}
                label={{
                  value: growthBySectorData[0]?.y_axis_label,
                  angle: -90,
                  dx: -17,
                  fontSize: 12,
                }}
              />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <Line type="monotone" dataKey={`${selectedPlace?.name}`} stroke="#f0a400" isAnimationActive={false} />
              <Line type="monotone" dataKey="South Africa" stroke="#009640" isAnimationActive={false} />
            </LineChart>
          </ResponsiveContainer>
          <div style={{ marginLeft: 70, height: 95 }}>
            <p style={{ margin: 0, fontSize: 14, textDecoration: "underline", marginLeft: 170 }}>{chart.data[chart.data.length - 1].year}</p>
            {Object.entries(chart.data[chart.data.length - 1]).map(([key, value], index) => {
              if (key !== "year") {
                return (
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 200 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: 10, height: 10, marginRight: 10, backgroundColor: growthBySectorColors[index - 1] }} />
                      <p style={{ margin: 0, width: 150 }}>{key}</p>
                    </div>

                    <p style={{ margin: 0, width: 50 }}>{value}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );

  const BarChartSmallChartComponent = ({ dataSlice, colors }) => (
    <div style={{ display: "flex", flexWrap: "wrap", marginLeft: 105 }}>
      {dataSlice.map((chart) => (
        <div style={{ marginRight: 20 }}>
          <div style={{ height: 90, width: 400 }}>{chart.graph_label.length >= 62 ? chart.graph_label.substring(0, 62) + " ..." : chart.graph_label}</div>
          <ResponsiveContainer width={300} height={400}>
            <BarChart
              data={chart.bar_chart[0].data}
              height={height * 0.4}
              width={(width - 400) * 0.3}
              margin={{
                top: 10,
                right: 10,
                left: 15,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <XAxis dataKey="band_label" interval={0} height={height * 0.35 * 0.3} tick={{ fontSize: 12, width: 30 }} />
              <YAxis
                label={{
                  value: exportersData[0]?.y_axis_label,
                  angle: -90,
                  dx: -15,
                }}
                domain={[0, 100]}
              />

              <Bar dataKey="value" isAnimationActive={false}>
                {chart.bar_chart[0].data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ))}
    </div>
  );

  const growthBySectorNumberOfPages = growthBySectorData?.length > 3 ? (growthBySectorData?.length > 9 ? 3 : 2) : 1;
  const exportersNumberOfPages = exportersData.length > 0 ? (exportersData?.length > 4 ? (exportersData?.length > 15 ? 3 : 2) : 1) : 0;
  const multiEstablishmentFirmsNumberOfPages = multiEstablishmentFirmsData.length > 0 ? (multiEstablishmentFirmsData?.length > 4 ? (multiEstablishmentFirmsData?.length > 15 ? 3 : 2) : 1) : 0;

  const indexHeaders =
    spatialParameter === "cat_b" && isMetroMunicipality
      ? [
          { title: "Jobs Growth", pageNumber: 1 },
          { title: "Industry Structure", pageNumber: 2 },
          { title: "Wage Distribution", pageNumber: 3 },
          { title: "Total Jobs Growth", pageNumber: 4 },
          { title: "New Jobs", pageNumber: 5 },
          { title: "Growth by Sector", pageNumber: 6 },
          { title: "Change in Metro", pageNumber: 6 + growthBySectorNumberOfPages },
          { title: "Industry Profile", pageNumber: 7 + growthBySectorNumberOfPages },
          { title: "Industry Potential", pageNumber: 8 + growthBySectorNumberOfPages },
          ...(exportersData.length > 0 ? [{ title: "Exporters", pageNumber: 10 + growthBySectorNumberOfPages }] : []),
          ...(multiEstablishmentFirmsData.length > 0 ? [{ title: "Multi-Establishment Firms", pageNumber: 10 + growthBySectorNumberOfPages + exportersNumberOfPages }] : []),
          { title: "Job Density", pageNumber: 10 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
          { title: "Wage Inequality", pageNumber: 11 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
          { title: "Wage Levels", pageNumber: 12 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
          { title: "Wage Distribution", pageNumber: 13 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
          { title: "Jobs for Youth", pageNumber: 14 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
        ]
      : [
          { title: "Jobs Growth", pageNumber: 1 },
          { title: "Industry Structure", pageNumber: 2 },
          { title: "Wage Distribution", pageNumber: 3 },
          { title: "Total Jobs Growth", pageNumber: 4 },
          { title: "New Jobs", pageNumber: 5 },
          { title: "Growth by Sector", pageNumber: 6 },
          { title: "Industry Profile", pageNumber: 6 + growthBySectorNumberOfPages },
          { title: "Industry Potential", pageNumber: 7 + growthBySectorNumberOfPages },
          ...(exportersData.length > 0 ? [{ title: "Exporters", pageNumber: 9 + growthBySectorNumberOfPages }] : []),
          ...(multiEstablishmentFirmsData.length > 0 ? [{ title: "Multi-Establishment Firms", pageNumber: 9 + growthBySectorNumberOfPages + exportersNumberOfPages }] : []),
          { title: "Wage Inequality", pageNumber: 9 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
          { title: "Wage Levels", pageNumber: 10 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
          { title: "Wage Distribution", pageNumber: 11 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
          { title: "Jobs for Youth", pageNumber: 12 + growthBySectorNumberOfPages + exportersNumberOfPages + multiEstablishmentFirmsNumberOfPages },
        ];

  const determineLineColor = (legendLabel) => {
    const lowercasedLabel = legendLabel.toLowerCase();

    switch (true) {
      case lowercasedLabel.includes("selected"):
        return "#f0a400";
      case lowercasedLabel.includes("highest"):
        return "black";
      case lowercasedLabel.includes("lowest"):
        return "#84746c";
      default:
        return "#84746c";
    }
  };

  const uniqueLabels = wageInequalityData.reduce((acc, curr) => {
    curr.line_details.forEach((item) => {
      Object.values(item).forEach((value) => {
        if (!acc.some((x) => x.label === value[0].line_label)) {
          acc.push({ label: value[0].line_label, color: determineLineColor(value[0].legend_label) });
        }
      });
    });
    return acc;
  }, []);

  const newData = wageInequalityData.map((item) => {
    const graphLabel = item.graph_label;
    const lines = item.line_details.reduce((acc, lines) => [...acc, ...Object.values(lines)], []);
    const yearValues = {};

    // eslint-disable-next-line array-callback-return
    lines.map((line) => {
      line.forEach((lineItem) => {
        lineItem.data.forEach((date) => {
          if (!yearValues[date.year]) {
            yearValues[date.year] = {
              year: date.year,
            };
          }

          yearValues[date.year][lineItem.line_label] = date.value;
        });
      });
    });

    return {
      graph_label: graphLabel,
      data: Object.values(yearValues),
    };
  });

  const wageLevelsUniqueLabels = wageLevelsResponseData.reduce((acc, curr) => {
    curr.line_details.forEach((item) => {
      Object.values(item).forEach((value) => {
        if (!acc.some((x) => x.label === value[0].line_label)) {
          acc.push({ label: value[0].line_label, color: determineLineColor(value[0].legend_label) });
        }
      });
    });
    return acc;
  }, []);

  const wageLevelsData = wageLevelsResponseData.map((item) => {
    const graphLabel = item.graph_label;
    const lines = item.line_details.reduce((acc, lines) => [...acc, ...Object.values(lines)], []);
    const yearValues = {};

    // eslint-disable-next-line array-callback-return
    lines.map((line) => {
      line.forEach((lineItem) => {
        lineItem.data.forEach((date) => {
          if (!yearValues[date.year]) {
            yearValues[date.year] = {
              year: date.year,
            };
          }

          yearValues[date.year][lineItem.line_label] = date.value;
        });
      });
    });

    return {
      graph_label: graphLabel,
      data: Object.values(yearValues),
    };
  });

  const ResponsiveText = ({ style, children }) => {
    const fontSize = 50 - children.length;
    return <Text style={{ ...style, fontSize }}>{children}</Text>;
  };

  return (
    <>
      <Box style={{ flex: 1, width: "100%", height: "100vh", zIndex: "21" }}>
        {jobsGrowthImageUrl &&
          industryStructureImageUrl &&
          wageDistributionImageUrl &&
          wageDistributionOverviewImageUrl &&
          totalJobsGrowthImageUrl &&
          newJobsImageUrl &&
          growthBySectorPageOneImageUrl &&
          growthBySectorPageTwoImageUrl &&
          growthBySectorPageThreeImageUrl &&
          industryProfileImageUrl &&
          industryPotentialImageUrl &&
          exportersPageOneImageUrl &&
          exportersPageTwoImageUrl &&
          exportersPageThreeImageUrl &&
          multiEstablishmentFirmsPageOneImageUrl &&
          multiEstablishmentFirmsPageTwoImageUrl &&
          multiEstablishmentFirmsPageThreeImageUrl &&
          jobsForYouthImageUrl &&
          wageLevelsImageUrl &&
          wageInequalityImageUrl && (
            <PDFViewer style={{ flex: 1, width: "100%", height: "100vh", zIndex: "21" }}>
              <Document>
                <Page size="A4" style={styles.coverPage.page}>
                  <View style={styles.coverPage.headerSection}>
                    <View style={{ width: 40 }} />
                    <View style={{ height: 202, width: 178 }}>
                      <Image style={styles.coverPage.headerImage} src={`data:image/png;base64,'${imageResponse[12].base64_image}'`}></Image>
                    </View>
                    <View style={styles.coverPage.headerTextSection}>
                      <ResponsiveText style={{ fontFamily: "Helvetica-Bold" }}>{displayMunicname.toUpperCase()}</ResponsiveText>
                      <Text style={styles.coverPage.subheading}>ECONOMIC INSIGHTS FROM TAX DATA</Text>
                      <Text style={styles.coverPage.text}>
                        Spatial Tax Portal Dashboard Report {`${new Date().getDate()}`.padStart(2, "0")}-{`${new Date().getMonth() + 1}`.padStart(2, "0")}-{new Date().getFullYear()}
                      </Text>
                      <Text style={styles.coverPage.text}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </View>
                  <View>
                    <Image style={{ height: 498 }} src={`data:image/png;base64,'${imageResponse[7].base64_image}'`}></Image>
                  </View>
                  <View style={styles.coverPage.footer}>
                    <Image style={styles.coverPage.footerImage} src={`data:image/png;base64,'${imageResponse[8].base64_image}'`}></Image>
                    <Image style={styles.coverPage.footerImage} src={`data:image/png;base64,'${imageResponse[9].base64_image}'`}></Image>
                    <Image style={styles.coverPage.footerImage} src={`data:image/png;base64,'${imageResponse[10].base64_image}'`}></Image>
                    <Image style={styles.coverPage.footerImage} src={`data:image/png;base64,'${imageResponse[11].base64_image}'`}></Image>
                  </View>
                </Page>
                <Page size="A4">
                  <View style={{ flex: 1, justifyContent: "flex-start", position: "relative" }}>
                    <Image style={styles.index.logo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.index.header}>INDEX</Text>
                    {indexHeaders.map((header) => (
                      <View style={{ flexDirection: "row", justifyContent: "space-between", width: "60%", marginLeft: 30 }}>
                        <Text style={styles.index.text}>{header.title}</Text>
                        <Text style={styles.index.text}>{header.pageNumber}</Text>
                      </View>
                    ))}
                    <View style={{ flexDirection: "column", width: "60%", position: "absolute", left: 30, bottom: 30 }}>
                      <Text style={styles.index.boldText}>Cover Photo/s Attribution</Text>
                      {imageResponse[7].attribution_list.map((attribution) => (
                        <Text style={styles.index.smallText}>
                          - {attribution?.photo_position}: {attribution?.photo_title} by {attribution?.photo_owner} on{" "}
                          <Link src={attribution?.source_link} style={styles.index.smallText}>
                            {attribution?.source_text}, {attribution.copyright_text}
                          </Link>
                        </Text>
                      ))}
                    </View>
                    <Image style={styles.index.sideImage} src={`data:image/png;base64,'${imageResponse[14].base64_image}'`}></Image>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#F0A400" }}>Overview</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>JOBS GROWTH {yearsRange?.length > 0 ? `${yearsRange[0]} - ${yearsRange[yearsRange?.length - 1]}` : ""}</Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={styles.image} src={jobsGrowthImageUrl} />
                  </View>
                  <View style={{ ...styles.legendSection, marginBottom: 20, marginTop: 10, display: "flex", flexDirection: "row", width: 300, justifyContent: "space-between" }}>
                    <Text style={{ ...styles.legendText, position: "absolute", top: -20, left: 240, fontSize: 14, textDecoration: "underline", marginBottom: 2 }}>{jobsGrowthData[jobsGrowthData.length - 1].year}</Text>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <View style={{ ...styles.legend, backgroundColor: "#f0a400" }} />
                      <Text style={styles.text}>Total jobs</Text>
                    </View>
                    <Text style={styles.text}>{jobsGrowthData[jobsGrowthData.length - 1].value}</Text>
                  </View>
                  <View>
                    <Text style={styles.paragraph}>How many jobs are available in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>Jobs reported in the spatial tax panel are based on IRP5 certificates which are converted into 'Full Time Equivalents' (FTE), or in other words, full time employment. The data covers employment by firms in the formal sector but excludes informal jobs where firms are not tax compliant. The total number of jobs is a critical indicator of the level of 'decent work' or size of the formal 'mainstream' economy.</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Jobs Growth" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#F0A400" }}>Overview</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>INDUSTRY STRUCTURE</Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
                  </View>
                  <View>
                    <Text style={styles.paragraph}>Which sectors are the biggest employers in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>
                      The top 10 sectors ranked here are most important for jobs and livelihoods. The economic outlook depends on the capacity of firms in these sectors to expand, adapt and innovate or otherwise risk becoming a drag on economic performance. Non-tradable sectors such as government services tend to be stable but are inherently constrained by the size of local economy (i.e. local demand). Tradable sectors can compete in surrounding (and even international) markets but must be
                      competitive to thrive.
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={{ ...styles.image, marginTop: 5, marginBottom: 5 }} src={industryStructureImageUrl} />
                  </View>
                  <View style={styles.legendSection}>
                    <Text style={styles.legendHeading}>Acronym - Full Name</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Gov - Public administration and defense, compulsory social security</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Admin Serv - Administrative and support activities</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Retail - Wholesale and retail trade, repair of motor vehicles and motorcycles</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Manu - Manufacturing</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Constru - Construction</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Educ - Education</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Finance - Finance</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Soc Serv - Human health and social work activities</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Prof - Professional, scientific and Technical activities</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Logistics - Transportation and storage</Text>
                    <Text style={{ ...styles.legendText, marginBottom: 5 }}>Other - Other Service Activities</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Industry Structure" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#F0A400" }}>Overview</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>WAGE DISTRIBUTION</Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={{ ...styles.image, marginTop: 5, marginBottom: 5 }} src={wageDistributionOverviewImageUrl} />
                  </View>
                  <View>
                    <Text style={styles.paragraph}>What do jobs pay for people working in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>The wage distribution shows the number of people who earn at a particular level in Rands per month. The level of earnings is closely related to demand-side factors such as sector of employment as well as supply-side factors such as worker experience, education and occupation. The number of people in each wage category is important to bear in mind considering the local population that these jobs support.</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Wage Distribution" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>TOTAL ECONOMIC GROWTH</Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={styles.subheading}>
                      Base year: {selectedFilters?.baseYear} | Toggles: {selectedFilters.isMetroDisplayed && "metros"}
                      {selectedFilters.isNationalDisplayed && ", national"}
                      {selectedFilters.isProvinceDisplayed && ", provinces"}
                      {selectedFilters.isSimilarSizeDisplayed && ", similar size"}{" "}
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={{ ...styles.image, marginTop: 10 }} src={totalJobsGrowthImageUrl} />
                  </View>
                  <TotalJobsGrowthLegendSection />
                  <View>
                    <Text style={styles.paragraph}>How does total jobs growth compare in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>
                      Indexed total jobs shows the relative performance of each region adjusted for its size in the starting year. It can also be interpreted as the total percentage change in jobs from the starting year by simply subtracting 100. Instability over time can be a consequence of a small economic base but also if the economy is stagnant which compresses the y-axis. Getting positioned above (or below) the ‘national’ benchmark implies that this local economy is pulling ahead (or
                      falling behind) the rest of the country.
                    </Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Total Economic Growth" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>NEW JOBS</Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={styles.subheading}>
                      Start year: {selectedFilters?.startYear} End year: {selectedFilters?.endYear}
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={styles.image} src={newJobsImageUrl} />
                  </View>
                  <View>
                    <Text style={styles.paragraph}>Which sectors created the most new jobs in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>The sectors which create the most new jobs have a critical role to play within the local economy. Job creation can be driven by economic expansion in dynamic sectors. However total jobs growth can also be related to industry size (or dominance) because modest and even low growth will add up to more jobs in large industries. In either case, these sectors mattered most for job creation.</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="New Jobs" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={{ ...styles.heading, marginBottom: 0 }}>GROWTH BY SECTOR</Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={styles.subheading}>
                      {industryFilter} | (1/{growthBySectorNumberOfPages})
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.paragraph}>How does jobs growth compare between and within sectors in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>
                      Total jobs growth hides important differences in performance both between and within sectors of the local economy. National trends are provided as a useful benchmark in each case. Exploring which particular sub-sectors are leading or lagging in jobs growth is critical for policy makers in better supporting local industry. Jobs growth has been indexed to show performance relative to industry size in all figures. Very small industries with less than or equal to 200 employees
                      have been omitted.
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={{ ...styles.image, marginTop: 20 }} src={growthBySectorPageOneImageUrl} />
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Growth By Sector" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                {growthBySectorOtherData?.length > 3 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={{ ...styles.heading, marginBottom: 0 }}>GROWTH BY SECTOR</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={{ ...styles.subheading, marginBottom: 0 }}>
                        {industryFilter} | (2/{growthBySectorNumberOfPages})
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 0 }} src={growthBySectorPageTwoImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Growth By Sector" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {growthBySectorOtherData?.length > 12 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={{ ...styles.heading, marginBottom: 0 }}>GROWTH BY SECTOR</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={{ ...styles.subheading, marginBottom: 0 }}>
                        {industryFilter} | (3/{growthBySectorNumberOfPages})
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 0 }} src={growthBySectorPageThreeImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Growth By Sector" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {spatialParameter === "cat_b" && isMetroMunicipality && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#FB8682" }}>Economic Growth</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>CHANGE IN METRO</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>
                        Start year: {selectedFilters?.startYear} End year: {selectedFilters?.endYear}
                      </Text>
                    </View>
                    <View style={{ ...styles.section, height: 600, position: "absolute", right: 5, top: 90 }}>
                      <Image style={styles.image} src={changeInMetroImageUrl} />
                    </View>
                    <View style={{ marginTop: 550 }}>
                      <Text style={styles.paragraph}>Where are jobs and firms being created or lost within {displayMunicname}?</Text>
                      <Text style={styles.paragraph}>Spatial patterns of economic change are a fundamental lens for local economic planning. Cities are made up of a mixed assortment of precincts with different levels of amenities and infrastructure investment. Spatial patterns can be explored in greater detail using the ‘map explorer’ tool.</Text>
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Change in Metro" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Industry Diagnostic</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>INDUSTRY PROFILE</Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={styles.image} src={industryProfileImageUrl} />
                  </View>
                  <View>
                    <Text style={styles.paragraph}>What is the structure of industry in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>Total jobs are split up across industries for sectors (inner pie) and sub-sectors (outer pie) to provide a detailed overview of the structure of local industry. Industries are defined according to the Standard Industrial Classification of all Economic Activities (seventh edition) which includes up to 90 divisions (sub-sectors in the outer pie) for an in-depth review.</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Industry Diagnostic" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Industry Diagnostic</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>Industry Potential: Four Quadrants</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.subheading}>
                      Start year: {selectedFilters?.startYear} End year: {selectedFilters?.endYear} | {industryPotentialIndustryFilter}
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={{ ...styles.image, marginTop: 10 }} src={industryPotentialImageUrl} />
                  </View>
                  <View style={{ ...styles.legendSection, marginBottom: 10 }}>
                    {industryPotentialData[0]?.plot_details[0]["1"][0].data
                      .sort((a, b) => b.z - a.z)
                      .map((legendItem, index) => (
                        <View key={index} style={{ ...styles.legendLine, marginBottom: 1 }}>
                          <View style={{ ...styles.legend, backgroundColor: legendItem.color }}></View>
                          <Text style={{ ...styles.legendText, fontSize: 9 }}>{legendItem.point_label}</Text>
                        </View>
                      ))}
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Industry Potential" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <View>
                    <Text break style={styles.paragraph}>
                      Which sectors show the most potential in {displayMunicname}?
                    </Text>
                    <Text style={styles.paragraph}>
                      The four quadrants is a tool to sort economic performance for industries across three dimensions: size of industry (bubble size), the location quotient (LQ) or industry specialisation (x-axis, horizontal) and jobs growth (y-axis, vertical). The LQ is a statistical way of a way of quantifying how concentrated a particular industry is (relative to the national average) and therefore the extent of specialisation. Together, these dimensions are indicative of ‘winners’ and
                      ‘losers’ where industries in the top right (i.e. solid bet) display the greatest potential. The industry toggle allows the quadrants model to be rerun to at the level of sub-sectors for any industry.
                    </Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Industry Potential" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                {exportersNumberOfPages > 0 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Industry Diagnostic</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>EXPORTERS</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>
                        Year: {selectedFilters?.singleYear} | (1/{exportersNumberOfPages})
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.paragraph}>How do the level of exports compare in {displayMunicname}?</Text>
                      <Text style={styles.paragraph}>
                        Data about exports is based on customs submissions which represents a sub-sample of for-profit firms (i.e. excludes government agencies and non-profit organisations). Exports data is only available at enterprise level which means that the local establishment (or branch) is part of a firm involved in international trade but may not import or export directly itself. For these reasons, the results are only suggestive of the extent of international trade within the local
                        economy. The figures do not include industries with less than 10 establishments in total.
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 20 }} src={exportersPageOneImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Exporters" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {exportersNumberOfPages > 1 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Economic Growth</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>EXPORTERS</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>
                        Year: {selectedFilters?.singleYear} | (2/{exportersNumberOfPages})
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 20 }} src={exportersPageTwoImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Exporters" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {exportersNumberOfPages > 2 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Economic Growth</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>EXPORTERS</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>
                        Year: {selectedFilters?.singleYear} | (3/{exportersNumberOfPages})
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 20 }} src={exportersPageThreeImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Exporters" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {multiEstablishmentFirmsNumberOfPages > 0 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Industry Diagnostic</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>MULTI-ESTABLISHMENT FIRMS</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>
                        Year: {selectedFilters?.singleYear} | (1/{multiEstablishmentFirmsNumberOfPages})
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.paragraph}>What types of firms are located in {displayMunicname}?</Text>
                      <Text style={styles.paragraph}>
                        Larger multi-establishment firms have more than one location or branch whereas single-location firms are dependent on local business conditions. Multi-establishments firms can take advantage of their size and national networks to reach and operate in diverse and even remote locations. Their expansion across multiple sites is a clear sign of business success. Deeper information about multi-establishment firms is difficult to spatialise because functions often differ
                        between branches. The figures do not include industries with less than 10 establishments in total.
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 20 }} src={multiEstablishmentFirmsPageOneImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Multi-establishment Firms" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {multiEstablishmentFirmsNumberOfPages > 1 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Economic Growth</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>MULTI-ESTABLISHMENT FIRMS</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>
                        Year: {selectedFilters?.singleYear} | (2/{multiEstablishmentFirmsNumberOfPages})
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 20 }} src={multiEstablishmentFirmsPageTwoImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Multi-establishment Firms" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {multiEstablishmentFirmsNumberOfPages > 2 && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Economic Growth</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>MULTI-ESTABLISHMENT FIRMS</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>
                        Year: {selectedFilters?.singleYear} | (3/{multiEstablishmentFirmsNumberOfPages})
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Image style={{ ...styles.image, marginTop: 20 }} src={multiEstablishmentFirmsPageThreeImageUrl} />
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Multi-establishment Firms" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                {spatialParameter === "cat_b" && isMetroMunicipality && (
                  <Page size="A4" style={styles.page}>
                    <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                    <Text style={styles.headerText}>
                      {selectedSpatialLayer?.name} | {displayMunicname}
                    </Text>
                    <View>
                      <Text style={{ ...styles.header, backgroundColor: "#009640" }}>Industry Diagnostic</Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={styles.heading}>JOB DENSITY</Text>
                    </View>
                    <View style={{ ...styles.section, paddingBottom: 0 }}>
                      <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
                    </View>
                    <View style={{ ...styles.section, height: 600, position: "absolute", right: 5, top: 90 }}>
                      <Image style={styles.image} src={jobDensityImageUrl} />
                    </View>
                    <View style={{ marginTop: 530 }}>
                      <Text style={styles.paragraph}>Where are jobs concentrated within {displayMunicname}?</Text>
                      <Text style={styles.paragraph}>The density or concentration of jobs is indicative of the form and function of cities. Cities sometimes include a number of sub-centres of economic activity with distinct specialisations. These hubs depend on sufficient investment in infrastructure to densify without becoming overloaded and congested. Spatial patterns can be explored in greater detail using the ‘map explorer’ tool.</Text>
                    </View>
                    <View style={styles.pageNumberContainer}>
                      <Text fixed style={styles.pageNumber}>
                        <PageNumber pageName="Job Density" />
                      </Text>
                    </View>
                    <View style={styles.footerContainer}>
                      <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                    </View>
                  </Page>
                )}
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#84746C" }}>Equitable Economies</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>Wage Inequality: Gini</Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={styles.image} src={wageInequalityImageUrl} />
                  </View>
                  <View style={{ ...styles.legendSection, marginBottom: 20, marginTop: 20 }}>
                    <Text style={{ ...styles.legendText, position: "absolute", top: -20, left: 220, fontSize: 14, textDecoration: "underline", marginBottom: 2 }}>{newData[0]?.data[newData[0].data.length - 1].year}</Text>
                    {Object.entries(newData[0]?.data[newData[0].data.length - 1]).map(([key, value], index) => {
                      if (key !== "year") {
                        return (
                          <View style={{ ...styles.legendLine, width: 300, justifyContent: "space-between" }}>
                            <View style={{ display: "flex", flexDirection: "row" }}>
                              <View style={{ ...styles.legend, backgroundColor: wageInequalityDataColors[index - 1] }} />
                              <Text style={styles.legendText}>{key}</Text>
                            </View>
                            <Text style={{ ...styles.legendText, width: 80 }}>{value}</Text>
                          </View>
                        );
                      }
                    })}
                  </View>
                  <View>
                    <Text style={styles.paragraph}>What is the level of wage inequality in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>
                      The gini coefficient is a statistical calculation of inequality where a value of 0 represents absolute equality and a value of 100 absolute inequality. The wage gini calculated from tax data is a measure of inequality amongst wage earners who work in the formal sector and is very different to measuring income inequality amongst all workers or amongst households (which would also include the unemployed). Wage inequality remains a fundamental driver of income inequality in
                      South Africa.
                    </Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Wage Inequality" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#84746C" }}>Equitable Economies</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>Wage Levels : Median Income</Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={styles.image} src={wageLevelsImageUrl} />
                  </View>
                  <View style={{ ...styles.legendSection, marginBottom: 20, marginTop: 20 }}>
                    <Text style={{ ...styles.legendText, position: "absolute", top: -20, left: 220, fontSize: 14, textDecoration: "underline", marginBottom: 2 }}>{wageLevelsData[0]?.data[wageLevelsData[0].data.length - 1].year}</Text>
                    {Object.entries(wageLevelsData[0]?.data[wageLevelsData[0].data.length - 1]).map(([key, value], index) => {
                      if (key !== "year") {
                        return (
                          <View style={{ ...styles.legendLine, width: 300, justifyContent: "space-between" }}>
                            <View style={{ display: "flex", flexDirection: "row" }}>
                              <View style={{ ...styles.legend, backgroundColor: wageLevelsColors[index - 1] }} />
                              <Text style={styles.legendText}>{key}</Text>
                            </View>
                            <Text style={{ ...styles.legendText, width: 80 }}>{value}</Text>
                          </View>
                        );
                      }
                    })}
                  </View>
                  <View>
                    <Text style={styles.paragraph}>How have average wages changed in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>Median income measures the middle or halfway point in wage rankings amongst formal workers. It is a less sensitive measurement of average earnings because it is not impacted by outliers. Median wage levels need to rise each year just to keep up with inflation. The average worker is better off where median wage levels are higher. Median wage levels reported in tax data exclude informal work and the unemployed.</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Wage Levels" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#84746C" }}>Equitable Economies</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>Wage Distribution</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.subheading}>
                      Year: {selectedFilters?.singleYear} | {wageDistributionIndustryFilter}
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={{ ...styles.image, marginTop: 10 }} src={wageDistributionImageUrl} />
                  </View>
                  <View style={{ ...styles.legendSection, marginBottom: 20, marginTop: 20 }}>
                    {Object.entries(wageDistributionData[0].data[wageDistributionData[0].data.length - 1]).map(([key, value], index) => {
                      if (key !== "wage_band" && key !== "x_axis_label" && key !== "y_axis_label") {
                        return (
                          <View style={{ ...styles.legendLine, width: 300, justifyContent: "space-between" }}>
                            <View style={{ display: "flex", flexDirection: "row" }}>
                              <View style={{ ...styles.legend, backgroundColor: uniqueLabels[index - 3]?.color }} />
                              <Text style={styles.legendText}>{key}</Text>
                            </View>
                          </View>
                        );
                      }
                    })}
                  </View>
                  <View>
                    <Text style={styles.paragraph}>How do earnings compare in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>The wage distribution offers a more detailed review of earnings than the median wage level or the wage gini. It shows the percentage of formal workers who fall in wage bands across the spectrum ranging from very low to very high earners. A bias to the right (left) suggests a concentration of higher (lower) income earners. The industry toggle allows for wage comparisons for specific industries.</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Wage Distribution" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.page}>
                  <Image style={styles.headerLogo} src={`data:image/png;base64,'${imageResponse[13].base64_image}'`}></Image>
                  <Text style={styles.headerText}>
                    {selectedSpatialLayer?.name} | {displayMunicname}
                  </Text>
                  <View>
                    <Text style={{ ...styles.header, backgroundColor: "#84746C" }}>Equitable Economies</Text>
                  </View>
                  <View style={styles.section}>
                    <Text style={styles.heading}>Jobs For Youth </Text>
                  </View>
                  <View style={{ ...styles.section, paddingBottom: 0 }}>
                    <Text style={styles.subheading}>Year: {selectedFilters?.singleYear}</Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={{ ...styles.image, marginTop: 20 }} src={jobsForYouthImageUrl} />
                  </View>
                  <View>
                    <Text style={styles.paragraph}>Which industries offer the most jobs for youth in {displayMunicname}?</Text>
                    <Text style={styles.paragraph}>Industries which create more job opportunities for young people can also play a development role within society. The total number of jobs for youth (aged 15 - 25) related to the intensity of youth employment in that sector along with total industry size. A higher youth jobs intensity (share of jobs for youth within the sector) is preferable although sheer industry size can lead to jobs for youth because of volume.</Text>
                  </View>
                  <View style={styles.pageNumberContainer}>
                    <Text fixed style={styles.pageNumber}>
                      <PageNumber pageName="Jobs For Youth" />
                    </Text>
                  </View>
                  <View style={styles.footerContainer}>
                    <Text style={styles.footer}>www.spatialtaxdata.org.za</Text>
                  </View>
                </Page>
                <Page size="A4" style={styles.backPage.page}>
                  <View style={styles.backPage.titleSection}>
                    <Text style={styles.backPage.title}>Primary Partnership</Text>
                  </View>
                  <Image style={styles.backPage.image} src={`data:image/png;base64,'${imageResponse[0].base64_image}'`}></Image>
                  <View style={styles.backPage.titleSection}>
                    <Text style={styles.backPage.title}>National Partners</Text>
                  </View>
                  <Image style={styles.backPage.image} src={`data:image/png;base64,'${imageResponse[1].base64_image}'`}></Image>
                  <View style={styles.backPage.titleSection}>
                    <Text style={styles.backPage.title}>Metro Partners</Text>
                  </View>
                  <Image style={styles.backPage.image} src={`data:image/png;base64,'${imageResponse[2].base64_image}'`}></Image>
                  <View style={styles.backPage.titleSection}>
                    <Text style={styles.backPage.title}>Donor Partners</Text>
                  </View>
                  <View style={styles.backPage.imageSection}>
                    <Image style={{ ...styles.backPage.image, height: 38 }} src={`data:image/png;base64,'${imageResponse[3].base64_image}'`}></Image>
                    <Image style={{ ...styles.backPage.image, height: 109 }} src={`data:image/png;base64,'${imageResponse[4].base64_image}'`}></Image>
                  </View>
                  <View style={styles.backPage.titleSection}>
                    <Text style={styles.backPage.title}>Technical Partners</Text>
                  </View>
                  <View style={styles.backPage.imageSection}>
                    <Image style={{ ...styles.backPage.image, height: 33 }} src={`data:image/png;base64,'${imageResponse[5].base64_image}'`}></Image>
                    <Image style={{ ...styles.backPage.image, height: 33 }} src={`data:image/png;base64,'${imageResponse[6].base64_image}'`}></Image>
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          )}
      </Box>
      <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1, width: "100%", height: "100%" }} className="opacity-30 bg-ccre-gray-900">
        <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
          <Typography sx={{ fontWeight: 800, fontSize: 20, mb: 4 }}>Loading...</Typography>
          <Typography sx={{ mb: 4 }}>We are busy generating your custom PDF report including:</Typography>
          <Typography>- Your selected spatial aggregation: Province or District or Muncipality etc</Typography>
          <Typography>- Your selected filter settings: Year, Industry, etc</Typography>
          <Typography sx={{ mt: 4, fontStyle: "italic" }}>This can take more than 2 minutes to load. Please wait until the process is complete. The option to download will be available thereafter.</Typography>
        </Box>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={jobsGrowthRef}>
          <ResponsiveContainer width={1400} height={500}>
            <LineChart
              data={jobsGrowthData}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
            >
              <XAxis dataKey="year" label={{ value: jobsGrowthData[0]?.x_axis_label, dy: 32 }} />
              <YAxis
                domain={[lowestValue, "auto"]}
                tickFormatter={(value) => numberFormatter(value, 2)}
                label={{
                  value: jobsGrowthData[0]?.y_axis_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <Line type="monotone" dataKey="value" stroke="#f0a400" strokeWidth={3} isAnimationActive={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div ref={industryStructureRef}>
          <ResponsiveContainer width={1400} height={500}>
            <BarChart
              data={industryStructureData}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <XAxis dataKey="activity_shortname" label={{ value: industryStructureData[0]?.x_axis_label, dy: 34 }} tickCount={11} interval={0} />
              <YAxis tickFormatter={(value) => numberFormatter(value, 2)} label={{ value: industryStructureData[0]?.y_axis_label, angle: -90, dx: -50 }} />
              <Bar dataKey="value" fill="#e69d00" isAnimationActive={false} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div ref={wageDistributionOverviewRef}>
          <ResponsiveContainer width={1400} height={525}>
            <BarChart
              data={wageDistributionOverviewData}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 125,
              }}
            >
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <XAxis
                interval={0}
                label={{
                  value: "Wage Bands",
                  dy: 130,
                }}
                dataKey="wage_band"
                tick={{
                  fontSize: 18,
                  textAnchor: "end",
                  dy: 2,
                  dx: -5,
                  angle: -90,
                }}
                tickCount={14}
              />
              <YAxis tickFormatter={(value) => numberFormatter(value, 2)} label={{ value: `Total Jobs: ${yearsRange[yearsRange?.length - 1]}`, angle: -90, dx: -50 }} />
              <Bar dataKey="value" isAnimationActive={false}>
                {wageDistributionOverviewData?.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={wageDistributionColors[index % wageDistributionColors?.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div ref={totalJobsGrowthRef}>
          <ResponsiveContainer width={1400} height={400}>
            <LineChart
              data={totalJobsGrowthData}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
            >
              <XAxis dataKey="year" label={{ value: "Year", dy: 32 }} />
              <YAxis domain={["auto", "auto"]} label={{ value: "Indexed Total Jobs", angle: -90, dx: -50 }} />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              {groupedMunicipalities.map((munic) => (
                <Line key={munic.place_name} type="monotone" dataKey={`${munic.place_name}`} stroke={`${munic.hex_colour}`} isAnimationActive={false} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div ref={newJobsRef}>
          <ResponsiveContainer width={1400} height={500}>
            <BarChart
              data={newJobsData}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <XAxis
                interval={0}
                dataKey="activity_shortname"
                label={{
                  value: newJobsData[0]?.x_axis_label,
                  dy: 34,
                }}
              />
              <YAxis
                tickFormatter={(value) => numberFormatter(value, 2)}
                label={{
                  value: newJobsData[0]?.y_axis_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <Bar dataKey="value" fill="#e69d00" isAnimationActive={false} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div ref={growthBySectorPageOneRef}>
          <ResponsiveContainer width={1400} height={500}>
            <LineChart
              data={growthBySectorMainData?.data}
              height={height * 0.45}
              width={width * 0.7}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 50,
              }}
            >
              <XAxis dataKey="year" />
              <YAxis
                domain={["auto", "auto"]}
                label={{
                  value: growthBySectorData[0]?.y_axis_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <Line type="monotone" dataKey={`${selectedPlace?.name}`} stroke="#f0a400" strokeWidth={3} isAnimationActive={false} />
              <Line type="monotone" dataKey="South Africa" stroke="#009640" strokeWidth={3} isAnimationActive={false} />
            </LineChart>
          </ResponsiveContainer>
          <div style={{ marginLeft: 150, marginBottom: 20 }}>
            {growthBySectorMainData?.data && (
              <>
                <p style={{ margin: 0, fontSize: 14, textDecoration: "underline", marginLeft: 250 }}>{growthBySectorMainData.data[growthBySectorMainData.data.length - 1].year}</p>
                {Object.entries(growthBySectorMainData.data[growthBySectorMainData.data.length - 1]).map(([key, value], index) => {
                  if (key !== "year") {
                    return (
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 300 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ width: 10, height: 10, marginRight: 10, backgroundColor: growthBySectorColors[index - 1] }} />
                          <p style={{ margin: 0, width: 150 }}>{key}</p>
                        </div>

                        <p style={{ margin: 0, width: 50 }}>{value}</p>
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>
          <GrowthBySectorSmallChartComponent dataSlice={growthBySectorOtherData.slice(0, 3)} />
        </div>
        <div ref={growthBySectorPageTwoRef}>
          <GrowthBySectorSmallChartComponent dataSlice={growthBySectorOtherData.slice(3, 6)} />
          <GrowthBySectorSmallChartComponent dataSlice={growthBySectorOtherData.slice(6, 9)} />
          <GrowthBySectorSmallChartComponent dataSlice={growthBySectorOtherData.slice(9, 12)} />
        </div>
        <div ref={growthBySectorPageThreeRef}>
          <GrowthBySectorSmallChartComponent dataSlice={growthBySectorOtherData.slice(12, 15)} />
          <GrowthBySectorSmallChartComponent dataSlice={growthBySectorOtherData.slice(15, 18)} />
        </div>
        <div ref={industryPotentialRef}>
          <ResponsiveContainer width={1400} height={400}>
            <ScatterChart
              width={730}
              height={250}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="x"
                domain={[0, 3]}
                label={{
                  value: industryPotentialData[0]?.plot_details[0]["1"][0].x_label,
                  dy: 32,
                }}
              />
              <YAxis
                tickFormatter={(value) => numberFormatter(value, 2)}
                domain={["auto", "auto"]}
                type="number"
                dataKey="y"
                label={{
                  value: industryPotentialData[0]?.plot_details[0]["1"][0].y_label,
                  angle: -90,
                  dx: -50,
                }}
              />
              <ZAxis dataKey="z" range={[200, 1000]} />
              <Scatter data={industryPotentialData[0]?.plot_details[0]["1"][0].data} isAnimationActive={false}>
                {industryPotentialData[0]?.plot_details[0]["1"][0].data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color ?? "#e69d00"} />
                ))}
              </Scatter>
              <ReferenceLine y={0} stroke="#000000" />
              <ReferenceLine x={1} stroke="#000000" />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
        <div ref={wageDistributionRef}>
          <ResponsiveContainer width={1400} height={500}>
            <LineChart
              data={wageDistributionData[0]?.data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 123,
              }}
            >
              <XAxis
                dataKey="wage_band"
                label={{
                  value: wageDistributionData[0]?.data[0]?.x_axis_label,
                  dy: 129,
                }}
                tick={{
                  fontSize: 18,
                  textAnchor: "end",
                  dy: 2,
                  dx: -5,
                  angle: -90,
                }}
                tickCount={14}
                interval={0}
              />
              <YAxis domain={["auto", "auto"]} label={{ value: wageDistributionData[0]?.data[0]?.y_axis_label, angle: -90, dx: -50 }} />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              {wageDistributionUniqueLabels.map((line, index) => (
                <Line key={index} type="monotone" dataKey={`${line.label}`} stroke={line.color} strokeWidth={2} isAnimationActive={false} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div ref={wageInequalityRef}>
          <ResponsiveContainer width={1400} height={400}>
            <LineChart
              data={newData[0]?.data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
            >
              <XAxis dataKey="year" label={{ value: wageInequalityData[0]?.x_axis_label, dy: 32 }} />
              <YAxis domain={["auto", "auto"]} label={{ value: wageInequalityData[0]?.y_axis_label, angle: -90, dx: -50 }} />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              {uniqueLabels.map((line, index) => (
                <Line key={index} type="monotone" dataKey={`${line.label}`} stroke={line.color} strokeWidth={3} isAnimationActive={false} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div ref={jobsForYouthRef}>
          {jobsForYouthData.map((graph, index) => (
            <div className="mb-5" key={index}>
              <div className="px-10 font-bold flex justify-center">{graph.graph_label}</div>
              <BarChart
                data={graph.data}
                width={1400}
                height={400}
                margin={{
                  top: 20,
                  right: 50,
                  left: 100,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3" vertical={false} />
                <XAxis dataKey="point_short_label" />
                <YAxis
                  tickFormatter={(value) => numberFormatter(value, 2)}
                  label={{
                    value: "Total Jobs",
                    angle: -90,
                    dx: -50,
                  }}
                />
                <Bar dataKey="y" fill={jobsForYouthColors[index]} isAnimationActive={false} />
              </BarChart>
            </div>
          ))}
        </div>
        <div ref={wageLevelsRef}>
          <ResponsiveContainer width={1400} height={400}>
            <LineChart
              data={wageLevelsData[0]?.data}
              margin={{
                top: 10,
                right: 50,
                left: 100,
                bottom: 26,
              }}
            >
              <XAxis dataKey="year" label={{ value: wageLevelsResponseData[0]?.x_axis_label, dy: 32 }} />
              <YAxis domain={["auto", "auto"]} label={{ value: wageLevelsResponseData[0]?.y_axis_label, angle: -90, dx: -50 }} />
              <CartesianGrid strokeDasharray="3" vertical={false} />
              {wageLevelsUniqueLabels.map((line, index) => (
                <Line key={index} type="monotone" dataKey={`${line.label}`} stroke={line.color} strokeWidth={3} isAnimationActive={false} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={industryProfileRef} style={{ marginLeft: -120 }}>
          <ResponsiveContainer width={920} height={500}>
            <PieChart width="100%" height="100%" barSize={400}>
              <Pie
                data={industryProfileData[0]?.plot_details[0][1][0].data}
                dataKey="size"
                cx="50%"
                cy="50%"
                outerRadius={160}
                startAngle={-270}
                fill="#1e6091"
                labelLine={false}
                label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = -50 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  if ((100 / industryProfileIndustryTotal) * value > 2) {
                    return (
                      <text x={x - 15} y={y} fill="#000000" textAnchor={"start"} dominantBaseline="central" style={{ fontSize: 12 }}>
                        {((100 / industryProfileIndustryTotal) * value).toFixed(2)}%
                      </text>
                    );
                  }
                }}
                isAnimationActive={false}
              >
                {industryProfileData[0]?.plot_details[0]["1"][0].data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.hex_colour} />
                ))}
              </Pie>
              <Pie data={industryProfileDataOuter} dataKey="size" cx="50%" cy="50%" innerRadius={170} outerRadius={220} startAngle={-270} fill="#82ca9d" isAnimationActive={false}>
                {industryProfileDataOuter?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.hex_colour} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div style={{ position: "absolute", left: 600, top: 50, width: 330 }}>
            {industryProfileData[0]?.plot_details[0]["1"][0].data.map((industry, index) => (
              <div className="flex mb-2" key={index}>
                <div style={{ backgroundColor: industry.hex_colour, width: 32, height: 24, marginRight: 5 }} />
                <div style={{ fontSize: 10, wordWrap: "break-word" }}>{industry.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={exportersPageOneRef}>
          {exportersData?.length > 0 && (
            <>
              <BarChartSmallChartComponent dataSlice={exportersData.slice(0, 3)} colors={exportersColors} />
              <BarChartSmallChartComponent dataSlice={exportersData.slice(3, 6)} colors={exportersColors} />
            </>
          )}
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={exportersPageTwoRef}>
          {exportersData?.length > 0 && (
            <>
              <BarChartSmallChartComponent dataSlice={exportersData.slice(6, 9)} colors={exportersColors} />
              <BarChartSmallChartComponent dataSlice={exportersData.slice(9, 12)} colors={exportersColors} />
              <BarChartSmallChartComponent dataSlice={exportersData.slice(12, 15)} colors={exportersColors} />
            </>
          )}
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={exportersPageThreeRef}>
          {exportersData?.length > 0 && (
            <>
              <BarChartSmallChartComponent dataSlice={exportersData.slice(15, 18)} colors={exportersColors} />
              <BarChartSmallChartComponent dataSlice={exportersData.slice(18, 21)} colors={exportersColors} />
            </>
          )}
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={multiEstablishmentFirmsPageOneRef}>
          {multiEstablishmentFirmsData?.length > 0 && (
            <>
              <BarChartSmallChartComponent dataSlice={multiEstablishmentFirmsData.slice(0, 3)} colors={multiEstablishmentFirmsColors} />
              <BarChartSmallChartComponent dataSlice={multiEstablishmentFirmsData.slice(3, 6)} colors={multiEstablishmentFirmsColors} />
            </>
          )}
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={multiEstablishmentFirmsPageTwoRef}>
          {multiEstablishmentFirmsData?.length > 0 && (
            <>
              <BarChartSmallChartComponent dataSlice={multiEstablishmentFirmsData.slice(6, 9)} colors={multiEstablishmentFirmsColors} />
              <BarChartSmallChartComponent dataSlice={multiEstablishmentFirmsData.slice(9, 12)} colors={multiEstablishmentFirmsColors} />
              <BarChartSmallChartComponent dataSlice={multiEstablishmentFirmsData.slice(12, 15)} colors={multiEstablishmentFirmsColors} />
            </>
          )}
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={multiEstablishmentFirmsPageThreeRef}>
          {multiEstablishmentFirmsData?.length > 0 && (
            <>
              <BarChartSmallChartComponent dataSlice={multiEstablishmentFirmsData.slice(15, 18)} colors={multiEstablishmentFirmsColors} />
              <BarChartSmallChartComponent dataSlice={multiEstablishmentFirmsData.slice(18, 21)} colors={multiEstablishmentFirmsColors} />
            </>
          )}
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={changeInMetroRef}>
          {industryStructureData?.length > 0 && (
            <div className="flex">
              <div className="w-[45%] ml-10">
                <div className="font-bold flex justify-center mb-5">Change in Jobs {selectedFilters ? `${selectedFilters.startYear} - ${selectedFilters.endYear}` : ""}</div>
                <DashboardMap key={`Jobs ${reportKey}`} reportKey={`Jobs ${reportKey}`} data={mapJobsData} data_grey={mapJobsDataGrey} metro={selectedPlace?.name} output={jobsOutput} viewAs={"Absolute Change"} maxValue={parseFloat(maxJobsValue)} minValue={parseFloat(minJobsValue)} />
              </div>
              <div className="w-[45%] ml-10">
                <div className="font-bold flex justify-center mb-5">Change in Firms {selectedFilters ? `${selectedFilters.startYear} - ${selectedFilters.endYear}` : ""}</div>
                <DashboardMap key={`Firms ${reportKey}`} reportKey={`Firms ${reportKey}`} data={mapFirmsData} data_grey={mapFirmsDataGrey} metro={selectedPlace?.name} output={firmsOutput} viewAs={"Absolute Change"} maxValue={parseFloat(maxFirmsValue)} minValue={parseFloat(minFirmsValue)} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ position: "absolute", left: "-10000px" }}>
        <div ref={jobDensityRef}>
          <div style={{ height: 1000, aspectRatio: 1 }}>
            <DashboardMap key={reportKey} reportKey={reportKey} data={mapData} data_grey={mapDataGrey} metro={selectedPlace?.name} output={"fte"} viewAs={"Absolute"} maxValue={parseFloat(maxValue)} minValue={parseFloat(minValue)} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDashboardsPdf;
