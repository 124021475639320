import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { cloneDeep } from "lodash";
import swal from "sweetalert";
import axiosNoAuthInstance from "../../axiosApiNoAuth";
// import hexData from "../../../data/Metros_hex.geo.json";
import DashboardMap from "./DashboardMap";
import { Box, CircularProgress } from "@mui/material";
import { jobDensityMapDataAtom, jobDensityMapDataGreyAtom, jobDensityMaxValueAtom, jobDensityMinValueAtom, jobDensityReportKeyAtom, selectedFiltersAtom, selectedPlaceAtom, selectedSpatialValueAtom, spatialParameterAtom, yearsRangeAtom } from "../../../shared/state/atoms";
import { useGeoJSON } from "../../../shared/hooks";

// const hexData = lazy(() => import('../../../data/Metros_hex.geo.json'));

function JobDensity() {
  // Use the useGeoJSON hook to load the GeoJSON data, this can also return a loading state if needed
  const { geoData: hexData } = useGeoJSON("Metros_hex.geo.json");

  const [maxValue, setMaxValue] = useAtom(jobDensityMaxValueAtom);
  const [minValue, setMinValue] = useAtom(jobDensityMinValueAtom);
  const [mapData, setMapData] = useAtom(jobDensityMapDataAtom);
  const [mapDataGrey, setMapDataGrey] = useAtom(jobDensityMapDataGreyAtom);
  const [reportKey, setReportKey] = useAtom(jobDensityReportKeyAtom);
  const [selectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [spatialParameter] = useAtom(spatialParameterAtom);
  const [selectedFilters] = useAtom(selectedFiltersAtom);
  const [yearsRange] = useAtom(yearsRangeAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // console.log('Loaded hexData in JOb Density.js:', hexData);

  useEffect(() => {

    // console.log('Inside useEffect:', hexData);

    // Only proceed if hexData is loaded
    if (!hexData) return;

    async function getMapData() {
      setIsLoading(true);
      setErrorMessage(null);
      var vfield, vget_field, vpoly_field, poly_data;
      poly_data = hexData; // Do not place in curly brackets

      var vpoly = cloneDeep(poly_data);
      var vpoly_grey = cloneDeep(poly_data);
      vfield = "display";
      vget_field = "hex7";
      vpoly_field = "hex7";
      var vmax = 0;
      var vmin = Number.MAX_SAFE_INTEGER;

      vpoly.features = [];
      vpoly_grey.features = [];

      axiosNoAuthInstance
        .get(`/api/map-explorer/?taxdata_modelname=hex7_fte&metro=${selectedPlace?.name}&year=${selectedFilters?.singleYear}&viewas=Absolute&secondyear=${yearsRange[yearsRange?.length - 1]}&spatial=hex7&output=fte&${spatialParameter}=${selectedSpatialValue}`)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < poly_data.features.length; j++) {
              if (poly_data.features[j].properties[vpoly_field] === response.data[i][vget_field]) {
                if (typeof response.data[i][vfield] != "number") {
                  let vfeature_grey = cloneDeep(poly_data.features[j]);
                  vfeature_grey.properties.value = "0";
                  vfeature_grey.properties.label = ["fte"];
                  vfeature_grey.properties.label.push(response.data[i][vfield]);
                  vpoly_grey.features.push(vfeature_grey);
                } else {
                  let vfeature = cloneDeep(poly_data.features[j]);
                  vfeature.properties.value = response.data[i][vfield];
                  if (parseFloat(vfeature.properties.value) > parseFloat(vmax)) {
                    vmax = parseFloat(vfeature.properties.value);
                  }
                  if (parseFloat(vfeature.properties.value) < parseFloat(vmin)) {
                    vmin = parseFloat(vfeature.properties.value);
                  }
                  vfeature.properties.label = ["fte"];
                  vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));

                  vpoly.features.push(vfeature);
                }
                continue;
              }
            }
          }
          setMaxValue(vmax);
          setMinValue(vmin === Number.MAX_SAFE_INTEGER ? 0 : vmin);
          setMapData(vpoly);
          setMapDataGrey(vpoly_grey);
          setReportKey(reportKey + 1);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            swal("Failed", `${error?.response?.data?.detail}`, "warning", {
              timer: 10000,
              buttons: false,
            });
          } else if (error?.response == null) {
            setIsLoading(false);
            setErrorMessage(`No Data available for ${selectedPlace?.name}`);
          } else {
            swal("Failed", `${error}`, "error", {
              timer: 4000,
              buttons: false,
            });
          }
        });
    }

    if (selectedPlace && selectedFilters?.baseYear) {
      getMapData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hexData, selectedPlace, spatialParameter, selectedSpatialValue, yearsRange, selectedFilters]);

  return (
    <div className="flex">
      <div className="w-full">
        <div className="text-lg px-10 pt-5 font-sans font-bold">{selectedPlace?.name} Industry Diagnostic</div>
        <div className="text-3xl font-bold px-10 mb-10 font-roboto">Density of Jobs in {yearsRange ? selectedFilters.singleYear : ""}</div>

        {!errorMessage ? (
          <div className="flex">
            <div className="w-[60%] ml-[20%]">
              {isLoading && (
                <div className="absolute left-0 top-0 w-full h-full opacity-30 bg-ccre-gray-900 z-20">
                  <Box sx={{ textAlign: "center", position: "relative", top: "40%" }}>
                    <CircularProgress color="success" size="3rem" />
                  </Box>
                </div>
              )}
              <DashboardMap key={reportKey} reportKey={reportKey} data={mapData} data_grey={mapDataGrey} metro={selectedPlace?.name} output={"fte"} viewAs={"Absolute"} maxValue={parseFloat(maxValue)} minValue={parseFloat(minValue)} />
            </div>
          </div>
        ) : (
          <div className="p-20 flex justify-center">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default JobDensity;
