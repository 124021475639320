import { useState, useEffect } from "react";

const useGeoJSON = (path) => {
  const [geoData, setGeoData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGeoJSON = async () => {
      try {
        const module = await import(`../../data/${path}`);
        setGeoData(module.default);
      } catch (error) {
        console.error(`Failed to load GeoJSON: ../../data/${path}`, error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    loadGeoJSON();
  }, [path]);

  return { geoData, loading };
};

export default useGeoJSON;