import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { BusinessOutlined, TravelExploreOutlined, TrendingFlatOutlined, TrendingUpOutlined } from "@mui/icons-material";
import ManIcon from "@mui/icons-material/Man";
// import axiosNoAuthInstance from "../../axiosApiNoAuth";
import { dashboardGroupings, dashboardLabels, dashboardRoutes, routeConstants } from "../../../shared/constants";
import { displayMunicNameAtom, selectedPlaceAtom, selectedSpatialLayerAtom, selectedSpatialValueAtom } from "../../../shared/state/atoms";
// import { transformKeys } from "../../../shared/utils";

function DashboardMenus({ municipalities, districts, provinces, selectedDashboard, availableSpatialLayers, setSelectedDashboard, setAvailableSpatialLayers }) {
  const [, setDisplayMunicName] = useAtom(displayMunicNameAtom);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false);
  const [isEconomicGrowthExpanded, setIsEconomicGrowthExpanded] = useState(false);
  const [isIndustryDiagnosticExpanded, setIsIndustryDiagnosticExpanded] = useState(false);
  const [isEquitableEconomiesExpanded, setIsEquitableEconomiesExpanded] = useState(false);
  const { dashboard } = useParams();

  const [selectedPlace, setSelectedPlace] = useAtom(selectedPlaceAtom);
  const [selectedSpatialValue, setSelectedSpatialValue] = useAtom(selectedSpatialValueAtom);
  const [selectedSpatialLayer, setSelectedSpatialLayer] = useAtom(selectedSpatialLayerAtom);

  const groupedDistricts = [];

  // Loop through the data and group objects based on the province
  districts?.forEach((item) => {
    const { province, province_n } = item;
    const existingProvinceIndex = groupedDistricts.findIndex((group) => group.province === province);
    if (existingProvinceIndex === -1) {
      groupedDistricts.push({ province, province_n, districts: [item] });
    } else {
      groupedDistricts[existingProvinceIndex].districts.push(item);
    }
  });

  useEffect(() => {
    if (!selectedPlace) {
      if (municipalities && selectedSpatialLayer?.id === "municipality") {
        const muncipality = municipalities.find((muncipality) => muncipality.cat_b === selectedSpatialValue);
        setSelectedPlace(muncipality);
      } else if (districts && selectedSpatialLayer?.id === "district") {
        const district = districts.find((district) => district.id === selectedSpatialValue);
        setSelectedPlace(district); // Runs concurrently to get at municipal info
      } else if (provinces && selectedSpatialLayer?.id === "province") {
        const province = provinces.find((province) => province.id === selectedSpatialValue);
        setSelectedPlace(province); // Runs concurrently to get at municipal info
      } else {
        console.log("Error: selectedPlace not set!");
        //return window.location.href = `/dashboards/` // Start again with correct values as cache was cleared
      }
    }
  }, [municipalities, districts, provinces, selectedPlace, selectedSpatialValue, selectedSpatialLayer, setSelectedPlace]);

  const overviewOptions = [
    { label: dashboardLabels[dashboardRoutes.JOBS_GROWTH], value: dashboardRoutes.JOBS_GROWTH },
    { label: dashboardLabels[dashboardRoutes.INDUSTRY_STRUCTURE], value: dashboardRoutes.INDUSTRY_STRUCTURE },
    {
      label: dashboardLabels[dashboardRoutes.WAGE_DISTRIBUTION_OVERVIEW],
      value: dashboardRoutes.WAGE_DISTRIBUTION_OVERVIEW,
    },
  ];

  const economicGrowthOptions = [
    { label: dashboardLabels[dashboardRoutes.TOTAL_JOBS_GROWTH], value: dashboardRoutes.TOTAL_JOBS_GROWTH },
    { label: dashboardLabels[dashboardRoutes.NEW_JOBS], value: dashboardRoutes.NEW_JOBS },
    { label: dashboardLabels[dashboardRoutes.GROWTH_BY_SECTOR], value: dashboardRoutes.GROWTH_BY_SECTOR },
    { label: dashboardLabels[dashboardRoutes.CHANGE_WITHIN_METRO], value: dashboardRoutes.CHANGE_WITHIN_METRO },
  ];

  const industryDiagnosticOptions = [
    {
      label: dashboardLabels[dashboardRoutes.INDUSTRY_PROFILE],
      value: dashboardRoutes.INDUSTRY_PROFILE,
    },
    { label: dashboardLabels[dashboardRoutes.INDUSTRY_POTENTIAL], value: dashboardRoutes.INDUSTRY_POTENTIAL },
    { label: dashboardLabels[dashboardRoutes.EXPORTERS], value: dashboardRoutes.EXPORTERS },
    {
      label: dashboardLabels[dashboardRoutes.MULTI_ESTABLISHMENT_FIRMS],
      value: dashboardRoutes.MULTI_ESTABLISHMENT_FIRMS,
    },
    { label: dashboardLabels[dashboardRoutes.JOB_DENSITY], value: dashboardRoutes.JOB_DENSITY },
    //Dominant sectors to be put on hold for now
    // { label: dashboardLabels[dashboardRoutes.DOMINANT_SECTORS], value: dashboardRoutes.DOMINANT_SECTORS },
  ];

  const equitableEconomiesOptions = [
    { label: dashboardLabels[dashboardRoutes.WAGE_INEQUALITY], value: dashboardRoutes.WAGE_INEQUALITY },
    { label: dashboardLabels[dashboardRoutes.WAGE_LEVELS], value: dashboardRoutes.WAGE_LEVELS },
    { label: dashboardLabels[dashboardRoutes.WAGE_DISTRIBUTIONS], value: dashboardRoutes.WAGE_DISTRIBUTIONS },
    { label: dashboardLabels[dashboardRoutes.JOBS_FOR_YOUTH], value: dashboardRoutes.JOBS_FOR_YOUTH },
  ];

  // Spatial Layers

  useEffect(() => {
    if (!availableSpatialLayers) {
      const spatialLayers = [
        { id: "municipality", name: "Municipality" },
        { id: "district", name: "District" },
        { id: "province", name: "Province" },
      ];
      setAvailableSpatialLayers(spatialLayers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSpatialLayers]);

  const handleSelectSpatialLayer = (option) => {
    const spatialLayer = availableSpatialLayers.find((spatialLayer) => spatialLayer.id === option);
    setSelectedSpatialLayer(spatialLayer);
    setSelectedPlace(); // Runs concurrently to get at municipal info
    setSelectedSpatialValue();
  };

  // Municipalities

  const handleSelectMunicipality = (option) => {
    if (municipalities) {
      const municipality = municipalities.find((municipality) => municipality.cat_b === option);
      setSelectedPlace(municipality); // Runs concurrently to get at municipal info
      setDisplayMunicName(municipality.name);
      setSelectedSpatialValue(option); // Always set id type value only
    } else {
      setSelectedSpatialValue(option); // Always set id type value only
    }
    if (selectedSpatialLayer?.id === "municipality" && selectedPlace?.category !== "A" && (selectedDashboard === dashboardRoutes.CHANGE_WITHIN_METRO || selectedDashboard === dashboardRoutes.JOB_DENSITY)) {
      window.history.pushState({}, "", `${routeConstants.DASHBOARDS}/${dashboardRoutes.GROWTH_BY_SECTOR}`);
      setSelectedDashboard(dashboardRoutes.GROWTH_BY_SECTOR);
    } else {
      window.history.pushState({}, "", `${routeConstants.DASHBOARDS}${`/${selectedDashboard ?? dashboardRoutes.JOBS_GROWTH}`}`);
    }
  };

  // Districts

  const handleSelectDistrict = (option) => {
    const district = districts.find((district) => district.id === option);

    setSelectedPlace(district); // This is where we are going
    setDisplayMunicName(district.name);
    setSelectedSpatialValue(option); // Always set id type value only

    if (selectedSpatialValue && selectedSpatialValue !== "") {
      window.history.pushState({}, "", `${routeConstants.DASHBOARDS}/${`/${selectedDashboard ?? dashboardRoutes.JOBS_GROWTH}`}`);
    }
  };

  // Provinces

  const handleSelectProvince = (option) => {
    const province = provinces.find((province) => province.id === option);
    // setSelectedProvince(province); // Runs concurrently to get at province info
    setSelectedPlace(province); // This is where we are going
    setDisplayMunicName(province.name);
    setSelectedSpatialValue(option); // Always set id type value only

    if (selectedSpatialValue && selectedSpatialValue !== "") {
      window.history.pushState({}, "", `${routeConstants.DASHBOARDS}${`/${selectedDashboard ?? dashboardRoutes.JOBS_GROWTH}`}`);
    }
  };

  const handleOverviewClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOverviewExpanded(true);
  };

  const handleEconomicGrowthClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsEconomicGrowthExpanded(true);
  };

  const handleIndustryDiagnosticClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsIndustryDiagnosticExpanded(true);
  };

  const handleEquitableEconomiesClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsEquitableEconomiesExpanded(true);
  };

  const handleMenuSelect = (option) => {
    setIsOverviewExpanded(false);
    setIsEconomicGrowthExpanded(false);
    setIsIndustryDiagnosticExpanded(false);
    setIsEquitableEconomiesExpanded(false);
    setSelectedDashboard(option);
    window.history.pushState({}, "", `${routeConstants.DASHBOARDS}/${option}`);
  };

  const isCurrentDashboardGroup = (group) => {
    return group.some((route) => route === selectedDashboard);
  };

  municipalities.sort((a, b) => a.name.localeCompare(b.name));

  const metros = municipalities.filter((obj) => obj.category === "A");

  const localMunicipalities = municipalities.filter((obj) => obj.category !== "A");

  return (
    <div className="w-[400px] my-10 px-5">
      <div className="grid p-4 border-2 border-gray-400">
        <div className="form-group bg-gray-200">
          {/* Spatial Layer Select Process*/}
          <select className="form-control hsrc-form-select border" name="spatial" value={selectedSpatialLayer?.id} onChange={(e) => handleSelectSpatialLayer(e.target.value)}>
            <option value="" className="font-bold text-lg text-black">
              Select geography...
            </option>
            {availableSpatialLayers?.map((e) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              );
            })}
          </select>
          {/* Spatial Layer Select Process*/}

          {/* Municipal Select Process*/}
          {selectedSpatialLayer?.id === "municipality" && (
            <select className="form-control hsrc-form-select border" name="municipality" value={selectedPlace?.cat_b} onChange={(e) => handleSelectMunicipality(e.target.value)}>
              <option value="">Select Municipality...</option>
              <option value="metros" disabled className="font-bold text-lg text-black">
                Metros
              </option>
              {metros?.map((e) => {
                return (
                  <option key={e.cat_b} value={e.cat_b}>
                    {e.name}
                  </option>
                );
              })}
              {dashboard !== "job-density" && dashboard !== "change-within-metro" ? (
                <>
                  <option value="local" disabled className="font-bold text-lg text-black">
                    Local Municipalities
                  </option>
                  {localMunicipalities?.map((e) => {
                    if (e.name === "Emalahleni") {
                      return (
                        <option key={e.cat_b} value={e.cat_b}>
                          {e.name + " (" + e.province + ")"}
                        </option>
                      );
                    } else {
                      return (
                        <option key={e.cat_b} value={e.cat_b}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </>
              ) : null}
            </select>
          )}
          {/* Municipal Select Process*/}

          {/* District Select Process*/}
          {selectedSpatialLayer?.id === "district" && (
            <select className="form-control hsrc-form-select border" name="district" value={selectedPlace?.id} onChange={(e) => handleSelectDistrict(e.target.value)}>
              <option value="" className="font-bold text-lg text-black">
                Select District...
              </option>
              {groupedDistricts.map((group) => (
                <>
                  <option value="metros" disabled className="font-bold text-lg text-black">
                    {group.province_n}
                  </option>
                  {group.districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </>
              ))}
            </select>
          )}
          {/* District Select Process*/}

          {/* Province Select Process*/}
          {selectedSpatialLayer?.id === "province" && (
            <select className="form-control hsrc-form-select border" name="province" value={selectedPlace?.id} onChange={(e) => handleSelectProvince(e.target.value)}>
              <option value="" className="font-bold text-lg text-black">
                Select Province...
              </option>
              {provinces?.map((e) => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          )}
          {/* Province Select Process*/}
        </div>
        {selectedSpatialValue && (
          <div className="flex justify-around">
            <div>
              <IconButton
                aria-label="more"
                id="overview-button"
                aria-controls={isOverviewExpanded ? "overview-button" : undefined}
                aria-expanded={isOverviewExpanded ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleOverviewClick}
                sx={{
                  "&:hover": { color: "#f0a400" },
                  color: isCurrentDashboardGroup(dashboardGroupings.overview) ? "#f0a400" : "#000",
                }}
              >
                <div>
                  <TravelExploreOutlined />
                  <div className="text-xs">Overview</div>
                </div>
              </IconButton>
              <Menu
                id="overview-menu"
                MenuListProps={{
                  "aria-labelledby": "overview-button",
                }}
                anchorEl={anchorEl}
                open={isOverviewExpanded}
                onClose={() => setIsOverviewExpanded(false)}
              >
                {overviewOptions.map((option) => (
                  <MenuItem key={option.value} onClick={() => handleMenuSelect(option.value)}>
                    {option.label}
                    {<TrendingFlatOutlined className="ml-5" />}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div>
              <IconButton
                aria-label="more"
                id="economic-growth-button"
                aria-controls={isEconomicGrowthExpanded ? "economic-growth-button" : undefined}
                aria-expanded={isEconomicGrowthExpanded ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleEconomicGrowthClick}
                sx={{
                  "&:hover": { color: "#fa7570" },
                  color: isCurrentDashboardGroup(dashboardGroupings.economicGrowth) ? "#fa7570" : "#000",
                }}
              >
                <div>
                  <TrendingUpOutlined />
                  <div className="text-xs">
                    Economic
                    <br />
                    Growth
                  </div>
                </div>
              </IconButton>
              <Menu
                id="economic-growth-menu"
                MenuListProps={{
                  "aria-labelledby": "economic-growth-button",
                }}
                anchorEl={anchorEl}
                open={isEconomicGrowthExpanded}
                onClose={() => setIsEconomicGrowthExpanded(false)}
              >
                {economicGrowthOptions.map(
                  (option) =>
                    ((selectedSpatialLayer?.id === "municipality" && selectedPlace?.category === "A") || option.value !== dashboardRoutes.CHANGE_WITHIN_METRO) && (
                      <MenuItem key={option.value} onClick={() => handleMenuSelect(option.value)}>
                        {option.label}
                        {<TrendingFlatOutlined className="ml-5" />}
                      </MenuItem>
                    )
                )}
              </Menu>
            </div>
            <div>
              <IconButton
                aria-label="more"
                id="industry-diagnostic-button"
                aria-controls={isIndustryDiagnosticExpanded ? "industry-diagnostic-button" : undefined}
                aria-expanded={isIndustryDiagnosticExpanded ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleIndustryDiagnosticClick}
                sx={{
                  "&:hover": { color: "#009640" },
                  color: isCurrentDashboardGroup(dashboardGroupings.industryDiagnostic) ? "#009640" : "#000",
                }}
              >
                <div>
                  <BusinessOutlined />
                  <div className="text-xs">
                    Industry
                    <br />
                    Diagnostic
                  </div>
                </div>
              </IconButton>
              <Menu
                id="industry-diagnostic-menu"
                MenuListProps={{
                  "aria-labelledby": "industry-diagnostic-button",
                }}
                anchorEl={anchorEl}
                open={isIndustryDiagnosticExpanded}
                onClose={() => setIsIndustryDiagnosticExpanded(false)}
              >
                {industryDiagnosticOptions.map(
                  (option) =>
                    ((selectedSpatialLayer?.id === "municipality" && selectedPlace?.category === "A") || option.value !== dashboardRoutes.JOB_DENSITY) && (
                      <MenuItem key={option.value} onClick={() => handleMenuSelect(option.value)}>
                        {option.label}
                        {<TrendingFlatOutlined className="ml-5" />}
                      </MenuItem>
                    )
                )}
              </Menu>
            </div>
            <div>
              <IconButton
                aria-label="more"
                id="equitable-economies-button"
                aria-controls={isEquitableEconomiesExpanded ? "equitable-economies-button" : undefined}
                aria-expanded={isEquitableEconomiesExpanded ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleEquitableEconomiesClick}
                sx={{
                  "&:hover": { color: "#84746c" },
                  color: isCurrentDashboardGroup(dashboardGroupings.equitableEconomies) ? "#84746c" : "#000",
                }}
              >
                <div>
                  <ManIcon />
                  <div className="text-xs">
                    Equitable
                    <br />
                    Economies
                  </div>
                </div>
              </IconButton>
              <Menu
                id="equitable-economies-menu"
                MenuListProps={{
                  "aria-labelledby": "equitable-economies-button",
                }}
                anchorEl={anchorEl}
                open={isEquitableEconomiesExpanded}
                onClose={() => setIsEquitableEconomiesExpanded(false)}
              >
                {equitableEconomiesOptions.map((option) => (
                  <MenuItem key={option.value} onClick={() => handleMenuSelect(option.value)}>
                    {option.label}
                    {<TrendingFlatOutlined className="ml-5" />}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardMenus;
